import React from 'react';
import { Paper, useTheme } from '@mui/material';
import Markdown from 'react-markdown';
import AccordionContentMarkdown from '../custom/AccordionContentMarkdown';

type AccordionProps = {
	title: string;
	content: string;
};

const titleFormattingStyle: React.CSSProperties = {
	whiteSpace: 'pre-wrap'
};

const contentFormattingStyle: React.CSSProperties = {
	whiteSpace: 'pre-wrap',
	display: 'flex',
	flexDirection: 'column'
};

export const Accordion = ({ title, content }: AccordionProps) => {
	const theme = useTheme();

	const paperSX = {
		'&:hover': {
			border: `1px solid ${theme.palette.primary.main}`,
			color: theme.palette.primary.main,
			cursor: 'pointer'
		}
	};

	return (
		<Paper elevation={0} className="tab-modal" sx={paperSX}>
			<input type="checkbox" id={title} name="rd" />
			<label className="tab-modal-label" htmlFor={title} style={titleFormattingStyle}>
				<Markdown>{title}</Markdown>
			</label>
			<div className="tab-modal-content" style={contentFormattingStyle}>
				<AccordionContentMarkdown content={content} />
			</div>
		</Paper>
	);
};
