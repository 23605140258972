import { useEffect, useRef } from 'react';
import { fetchAnalyticsResults } from '../../../app/thunks/fetch-results';
import { useAppDispatch } from '../../../app/hooks';

const RETRY_TIMEOUT_MS = 1500;

export const useObserveAnalyticsResults = () => {
	const intervalRef = useRef<number | undefined>(undefined);

	const dispatch = useAppDispatch();

	useEffect(() => {
		intervalRef.current = window.setInterval(() => {
			dispatch(fetchAnalyticsResults());
		}, RETRY_TIMEOUT_MS);

		return () => {
			clearInterval(intervalRef.current);
		};
	}, []);
};
