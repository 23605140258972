import { customizationsActions, Language } from '../slices/customizations.slice';
import { customizationService } from '../../services/CustomizationService';
import { AppDispatch } from '../store';

const LANGUAGES: Array<Language> = ['en', 'de'];

export const loadCustomizations = (token: string) => {
	return async (dispatch: AppDispatch) => {
		await Promise.all(
			LANGUAGES.map(async (language) => {
				const customizations = await customizationService.fetchCustomizations(language, token);

				dispatch(
					customizationsActions.setCustomizations({
						language: language,
						data: customizations
					})
				);
			})
		);
	};
};
