import React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { useFAQMarkdownComponents } from '../../hooks/useFAQMarkdownComponents';

interface CustomMarkdownProps {
	content: string;
}

const AccordionContentMarkdown: React.FC<CustomMarkdownProps> = ({ content }) => {
	const customComponents = useFAQMarkdownComponents();

	return (
		<Markdown
			remarkPlugins={[remarkGfm]}
			rehypePlugins={[rehypeRaw]}
			components={customComponents}
			className="accordion-content-custom-markdown"
		>
			{content}
		</Markdown>
	);
};

export default AccordionContentMarkdown;
