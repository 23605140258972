import React from 'react';
import { Persona } from '../../../types/Persona';
import { ReactComponent as UserIcon } from '../../../assets/images/icons/user.svg';
import { useTheme } from '@mui/material';
import { UserInformationContainer } from './UserInformationContainer';

export interface UserInformationProps {
	persona: Persona;
}

export const UserInformation: React.FC<UserInformationProps> = ({ persona }) => {
	return (
		<div className="container-personal-info">
			<div className="user-icon-wrapper">
				<UserInformationIcon />
			</div>
			<UserInformationContainer persona={persona} />
		</div>
	);
};

const UserInformationIcon = () => {
	const theme = useTheme();
	const fill = theme.palette.primary.main;
	return <UserIcon className="user-icon" fill={fill} />;
};
