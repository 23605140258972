import React from 'react';
import { CustomizationKey } from '../../app/slices/customizations.slice';
import useCustomization from '../../hooks/useCustomization';
import LegalDocsMarkdown from '../custom/LegalDocsMarkdown';

const XS2A_TERMS_KEY: CustomizationKey = 'XS2A_TERMS';

const XS2ATermsModalCustomization = () => {
	const textCustomization = useCustomization(XS2A_TERMS_KEY);

	return <LegalDocsMarkdown content={textCustomization} />;
};

export default XS2ATermsModalCustomization;
