import React from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import calendar from '../../assets/images/icons/calendar.svg';
import { useTheme } from '@mui/material';
import moment from 'moment';

type CustomDatePickerProps = {
	initValue?: Date | null;
	placeholder: string;
	onChange: (param: { isValid: boolean; date: Date | null }) => void;
	id: string;
	disabled: boolean;
	maxDate?: any;
};

const CustomDatePicker = ({
	placeholder,
	onChange,
	id,
	initValue = null,
	disabled = false,
	maxDate
}: CustomDatePickerProps) => {
	const [value, setValue] = React.useState<Date | null>(initValue);
	const [error, setError] = React.useState<boolean>(false);
	const theme = useTheme();

	const handleChange = (value: Date | null) => {
		setValue(value);
		//@ts-ignore
		if (value && value._isValid && moment(value).diff(maxDate, 'days') <= 0) {
			if (error) setError(false);
			onChange({
				isValid: true,
				date: value
			});
		} else {
			setError(true);
			onChange({ isValid: false, date: value });
		}
	};

	const errorStyle = error
		? {
				border: '1px solid red !important'
		  }
		: {};

	const style = {
		width: '100%',
		marginTop: '20px',
		input: { color: `${error ? '#FF0000' : 'black'}` },
		'&:focus-within': {
			border: `1px solid ${theme.palette.primary.main} !important`
		},
		...errorStyle
	};
	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<DesktopDatePicker
				defaultCalendarMonth={maxDate}
				maxDate={maxDate as any}
				disabled={disabled}
				mask=""
				label={placeholder}
				value={value}
				onChange={handleChange}
				inputFormat={'DD.MM.YYYY'}
				components={{
					OpenPickerIcon: () => <img src={calendar} className="calendar-open-image" alt="calendar-image" />
				}}
				renderInput={(params) => (
					<TextField
						id={id}
						variant="filled"
						{...params}
						helperText={null}
						sx={style}
						InputLabelProps={{
							style: {
								color: `${error ? '#FF0000' : '#707070'}`,
								fontSize: '18px'
							}
						}}
						className={`txt-field-date-picker ${error ? 'Mui-error' : ''}`}
					/>
				)}
			/>
		</LocalizationProvider>
	);
};

export default React.memo(CustomDatePicker);
