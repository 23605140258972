import Modal from '../custom/Modal';
import Close from '../../assets/images/icons/close.svg';
import { useDispatch } from 'react-redux';
import { ModalEnum, uiActions } from '../../app/slices/ui.slice';
import ImprintModalCustomization from '../customizations/ImprintModalCustomization';

const ImpressumModal = () => {
	const dispatch = useDispatch();

	const handleOnCloseModal = () => {
		dispatch(uiActions.toggleModal(ModalEnum.IMPRESSIUM_MODAL));
	};

	return (
		<Modal onClose={handleOnCloseModal}>
			<div className="modal-actions">
				<div className="modal-actions-close" onClick={handleOnCloseModal}>
					<img className="modal-close" src={Close} alt="Close" />
				</div>
			</div>
			<ImprintModalCustomization />
		</Modal>
	);
};

export default ImpressumModal;
