import React from 'react';
import ErrorStep from './ErrorStep';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { handleError, selectErrorType } from '../../app/slices/errors.slice';

const ErrorStepsWrapper = () => {
	const errorType = useAppSelector(selectErrorType);
	const dispatch = useAppDispatch();

	if (!errorType) {
		dispatch(handleError(new Error('Error type not defined: can not render error wrapper')));
		return <></>;
	}

	return (
		<div>
			<ErrorStep errorType={errorType} />
		</div>
	);
};

export default ErrorStepsWrapper;
