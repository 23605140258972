import { BankSdkCustomizations, CustomizationKey } from '../app/slices/customizations.slice';
import useMDLinkEventHandler from './useMDLinkEventHandler';
import useCustomization from './useCustomization';
import ForwardingPageXS2ACustomization from '../components/customizations/ForwardingPageXS2ACustomization';
import React from 'react';

const FORWARDING_PAGE_SUBTITLE_KEY: CustomizationKey = 'FORWARDING_PAGE_SUBTITLE';

type BankSdkCustomizationsExtended = {
	[key in keyof BankSdkCustomizations]: {
		[prop in keyof BankSdkCustomizations[key]]: BankSdkCustomizations[key][prop] | JSX.Element;
	};
};

const useForwardingSubtitleCustomization = (data: BankSdkCustomizations) => {
	const newData: BankSdkCustomizationsExtended = { ...data };
	const handleMDLinkClick = useMDLinkEventHandler();
	const customizationContent = useCustomization(FORWARDING_PAGE_SUBTITLE_KEY);

	if (isForwardingCustomizationProvided(newData)) {
		newData.en.FORWARDING_PAGE_SUBTITLE = (
			<ForwardingPageXS2ACustomization
				customizationContent={customizationContent}
				handleMDLinkClick={handleMDLinkClick}
			/>
		);
		newData.de.FORWARDING_PAGE_SUBTITLE = (
			<ForwardingPageXS2ACustomization
				customizationContent={customizationContent}
				handleMDLinkClick={handleMDLinkClick}
			/>
		);
	}

	return newData;
};

export default useForwardingSubtitleCustomization;

const isForwardingCustomizationProvided = (customizations: BankSdkCustomizationsExtended): boolean => {
	return Boolean(
		customizations.en &&
			customizations.en.FORWARDING_PAGE_SUBTITLE &&
			customizations.de &&
			customizations.de.FORWARDING_PAGE_SUBTITLE
	);
};
