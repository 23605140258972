import React from 'react';
import PersonalConsentFooterCustomization from '../customizations/PersonalConsentFooterCustomization';
import { CustomizationKey } from '../../app/slices/customizations.slice';
import useMDLinkEventHandler from '../../hooks/useMDLinkEventHandler';
import useCustomization from '../../hooks/useCustomization';

const ConsentText = () => {
	const PERSONAL_CONSENT_FOOTER_TEXT_KEY: CustomizationKey = 'PERSONAL_CONSENT_FOOTER_TEXT';
	const customizationContent = useCustomization(PERSONAL_CONSENT_FOOTER_TEXT_KEY);
	const handleMDLinkClick = useMDLinkEventHandler();

	return (
		<div className="personal-consent-footer">
			<PersonalConsentFooterCustomization
				customizationContent={customizationContent}
				handleMDLinkClick={handleMDLinkClick}
			/>
		</div>
	);
};

export default ConsentText;
