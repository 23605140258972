import React, { useState } from 'react';
import { WithChildren } from '../custom/SingleSpinner';
import { useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import { selectCheckLanguage } from '../../app/slices/metadata.slice';

type LanguageSetterProps = WithChildren;

const LanguageSetter = ({ children }: LanguageSetterProps) => {
	const [setup, setSetup] = useState<boolean>(false);

	const checkLanguage = useAppSelector(selectCheckLanguage);
	const { i18n } = useTranslation();

	if (!setup) {
		const language = getCheckLanguageOrDefault(checkLanguage);
		i18n.changeLanguage(language);
		setSetup(true);
	}

	return <>{children}</>;
};

export default React.memo(LanguageSetter);

const supportedLanguages = ['de', 'en'];
const DEFAULT_LANG = 'de';

function getCheckLanguageOrDefault(lang: string | undefined) {
	if (!lang) return DEFAULT_LANG;

	const lowercaseLanguage = lang.toLowerCase();
	const isLanguageSupported = supportedLanguages.includes(lowercaseLanguage);
	if (isLanguageSupported) return lowercaseLanguage;
	else return DEFAULT_LANG;
}
