import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PersonalInfoInput from './PersonalInfoInput';
import PersonalConsent from './PersonalConsent';
import BankInterface from './BankInterface';
import Loading from '../custom/Loading';
import StepComponent from './StepComponent';
import { BUTTON_TEXT_VALUES, EnumSteps } from '../../types/constants';
import StepButton from './StepButton';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ResultsWrapper from './ResultsWrapper';
import { EnumResultsPageType } from '../../types/Reports';
import { useTranslation } from 'react-i18next';
import DiscardResultsText from './DiscardResultsText';
import ConsentText from './ConsentText';
import ResultsSummaryText from './ResultsSummaryText';
import ErrorStepsWrapper from '../error/ErrorStepsWrapper';
import FinalStep from './final/FinalStep';
import WaitingAnalyticsResults from './analytics-results/WaitingAnalyticsResults';
import { uiActions } from '../../app/slices/ui.slice';
import { selectPersona } from '../../app/slices/persona.slice';
import { handleNextThunk } from '../../app/thunks/report';
import { CompleteStatus } from '../../app/thunks/complete-check';
import EmailSentScreen from './email/EmalSentStep';
import BlankCheckInputStep from './blank-check-input-step/BlankCheckInputStep';
import EmailVerifiedStep from './email/EmailVerifiedStep';

const Steps: React.FC = () => {
	const dispatch = useAppDispatch();
	const activeStep = useAppSelector((state) => state.ui.activeStep);
	const loading = useSelector((state: any) => state.ui.loading);
	const persona = useAppSelector(selectPersona);
	const pageResultViewType = useAppSelector((state) => state.ui.checkResultViewType);
	const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
	const [processing, setProcessing] = useState<boolean>(false);

	const { t } = useTranslation();
	let buttonJSX: JSX.Element;
	const isValidFormPersonalInfo = useCallback(() => {
		const {
			isValidFirstName,
			isValidLastName,
			residence: { country },
			isValidDateOfBirth
		} = persona;

		if (isValidFirstName && isValidLastName && country && isValidDateOfBirth) {
			setIsNextButtonDisabled(false);
		} else {
			setIsNextButtonDisabled(true);
		}
	}, [persona]);

	const isValidFormPersonalConsent = useCallback(() => {
		const { consentCollectingData, consentForwardingData } = persona;

		if (consentCollectingData && consentForwardingData) {
			setIsNextButtonDisabled(false);
		} else {
			setIsNextButtonDisabled(true);
		}
	}, [persona]);

	const checkIfButtonIsDisabled = useCallback(() => {
		switch (activeStep.id) {
			case EnumSteps.PersonalInfoStep:
				isValidFormPersonalInfo();
				break;
			case EnumSteps.PersonalConsentStep:
				isValidFormPersonalConsent();
				break;
			default:
				break;
		}
	}, [activeStep.id, isValidFormPersonalInfo, isValidFormPersonalConsent]);

	useEffect(() => {
		checkIfButtonIsDisabled();
	}, [persona, checkIfButtonIsDisabled]);

	const handleNext = async (status?: string) => {
		const param = status ? { status } : {};
		setProcessing(true);

		await dispatch(handleNextThunk(param));
		setProcessing(false);

		// if previous active step is Bank Interface step, disable button
		const previousStep = activeStep;
		if (
			previousStep.id !== EnumSteps.PersonalConsentStep &&
			previousStep.id !== EnumSteps.WaitingAnalyticsResultsStep
		) {
			setIsNextButtonDisabled(true);
			if (previousStep.id === EnumSteps.PersonalInfoStep) isValidFormPersonalConsent();
		}
	};

	const isButtonDisabled = () => {
		return isNextButtonDisabled || processing;
	};

	const onCheckComplete = async (status: CompleteStatus) => {
		setAppWaitingStyle();
		await handleNext(status);
		disableAppWaitingStyle();
	};

	const changePageTypeToResultView = (ptype: EnumResultsPageType) => {
		dispatch(uiActions.setCheckResultsViewType(ptype));
	};

	const enableNextButton = () => {
		setIsNextButtonDisabled(false);
	};

	if (activeStep.id === EnumSteps.PersonalCheckInfo) {
		buttonJSX = (
			<div className="step-btn-component">
				<StepButton
					disabled={isButtonDisabled()}
					className={`${isNextButtonDisabled && 'disabled-next-button'}`}
					onClick={() => onCheckComplete('accepted')}
					title={t(`button.complete`)}
				/>
			</div>
		);
	} else {
		const btnText = activeStep.id === '0' ? '' : t(`button.${BUTTON_TEXT_VALUES[activeStep.id]}`);
		buttonJSX = (
			<div className="step-btn-component">
				<StepButton
					disabled={isButtonDisabled()}
					className={`${isNextButtonDisabled && 'disabled-next-button'}`}
					onClick={() => handleNext()}
					title={btnText}
				/>
			</div>
		);
	}

	if (
		activeStep.id === EnumSteps.NoEmailStep ||
		activeStep.id === EnumSteps.EmailSentStep ||
		activeStep.id === EnumSteps.EmailVerifiedStep
	) {
		buttonJSX = <></>;
	}

	const StepMainComponent = () => {
		switch (activeStep.id) {
			case EnumSteps.NoEmailStep:
				return <BlankCheckInputStep />;
			case EnumSteps.EmailSentStep:
				return <EmailSentScreen />;
			case EnumSteps.EmailVerifiedStep:
				return <EmailVerifiedStep />;
			case EnumSteps.PersonalInfoStep:
				return <PersonalInfoInput onValidFormPersonalInfo={isValidFormPersonalInfo} />;
			case EnumSteps.PersonalConsentStep:
				return <PersonalConsent onValidFormPersonalConsent={isValidFormPersonalConsent} />;
			case EnumSteps.BankInterfaceStep:
				return <BankInterface />;
			case EnumSteps.WaitingAnalyticsResultsStep:
				return <WaitingAnalyticsResults />;
			case EnumSteps.PersonalCheckInfo:
				return <ResultsWrapper init={enableNextButton} />;
			case EnumSteps.FinalStep:
				return <FinalStep />;
			case EnumSteps.ErrorStep:
				return <ErrorStepsWrapper />;
			default:
				break;
		}
	};

	if (loading) {
		return (
			<div className="box-steps">
				<div className="steps">
					<Loading />
				</div>
			</div>
		);
	}

	// @ts-ignore
	return (
		<div className="box-steps">
			<div className="steps">
				<StepComponent>{StepMainComponent()}</StepComponent>

				{activeStep.id !== EnumSteps.BankInterfaceStep &&
					activeStep.id !== EnumSteps.WaitingAnalyticsResultsStep &&
					activeStep.id !== EnumSteps.FinalStep &&
					activeStep.id !== EnumSteps.LoadingStep &&
					activeStep.id !== EnumSteps.ErrorStep && <>{buttonJSX}</>}

				{activeStep.id === EnumSteps.PersonalCheckInfo &&
					pageResultViewType === EnumResultsPageType.RESULTS_SUMMARY && (
						<ResultsSummaryText
							onDetailsClick={() => changePageTypeToResultView(EnumResultsPageType.RESULTS_VIEW)}
						/>
					)}

				{activeStep.id === EnumSteps.PersonalCheckInfo && (
					<DiscardResultsText onDiscard={() => onCheckComplete('discarded')} disabled={isButtonDisabled()} />
				)}

				{activeStep.id === EnumSteps.PersonalConsentStep && <ConsentText />}
			</div>
		</div>
	);
};

export default Steps;

const setAppWaitingStyle = () => {
	const appElement = getAppElement();
	appElement.style.cursor = 'wait';
};

const disableAppWaitingStyle = () => {
	const appElement = getAppElement();
	appElement.style.cursor = 'default';
};

const getAppElement = () => {
	const elementCollection = document.getElementsByClassName('App');
	return elementCollection.item(0) as HTMLElement;
};
