import React from 'react';
import { CustomizationKey } from '../../app/slices/customizations.slice';
import useCustomization from '../../hooks/useCustomization';
import LegalDocsMarkdown from '../custom/LegalDocsMarkdown';

const XS2A_PRIVACY_KEY: CustomizationKey = 'XS2A_PRIVACY';

const XS2APrivacyModalCustomization = () => {
	const textCustomization = useCustomization(XS2A_PRIVACY_KEY);

	return <LegalDocsMarkdown content={textCustomization} />;
};

export default XS2APrivacyModalCustomization;
