import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICheckItem } from '../../types/Reports';
import { ResultsOrderStep } from '../../check/codes';
import { RootState } from '../store';

export type ReportStatusType =
	| 'PENDING'
	| 'PROCESSING'
	| 'READY'
	| 'ACCEPTED'
	| 'DISCARDED'
	| 'CANCELLED'
	| 'NO_EMAIL'
	| 'EMAIL_VALIDATION_PENDING';

export type ReportSliceState = {
	kontoCheck: Array<any>;
	household: Array<any>;
	namecheck?: object;
	reportStatus: ReportStatusType;
	userAccepted: boolean;
	score?: ICheckItem;
	lights?: ICheckItem;
};

const initialState: ReportSliceState = {
	kontoCheck: [],
	household: [],
	reportStatus: 'PENDING',
	userAccepted: false
};

const sortOrderAndRemoveDuplicates = (payload: any) => {
	return payload
		.sort((a: any, b: any) => (ResultsOrderStep[a.code] > ResultsOrderStep[b.code] ? 1 : -1))
		.filter((value: ICheckItem, index: any, self: any[]) => index === self.findIndex((t) => t.code === value.code));
};

const reportsSlice = createSlice({
	name: 'reports',
	initialState,
	reducers: {
		setKontoCheck(state, action) {
			state.kontoCheck = sortOrderAndRemoveDuplicates(action.payload);
		},
		setHousehold(state, action) {
			state.household = sortOrderAndRemoveDuplicates(action.payload);
		},
		setNamecheck(state, action: PayloadAction<ICheckItem>) {
			state.namecheck = action.payload;
		},
		setScore(state, action: PayloadAction<ICheckItem>) {
			state.score = action.payload;
		},
		setLights(state, action: PayloadAction<ICheckItem>) {
			state.lights = action.payload;
		},
		setReportStatus(state, action) {
			state.reportStatus = action.payload;
		},
		setCommentForKontoCheckReport(state, action) {
			state.kontoCheck.forEach((konto) => {
				for (const val in konto) {
					if (konto[val] === action.payload.code) {
						// @ts-ignore
						konto['comment'] = action.payload.comment;
					}
				}
			});
		},
		setCommentForHouseholdCheckReport(state, action) {
			state.household.forEach((inOutFlow) => {
				for (let val in inOutFlow) {
					if (inOutFlow[val] === action.payload.code) {
						// @ts-ignore
						inOutFlow['comment'] = action.payload.comment;
						// if there is new value, change it
						const analytics_result = inOutFlow['result'];
						if (action.payload.value && Number.parseFloat(action.payload.value) !== analytics_result) {
							// @ts-ignore
							inOutFlow['newResult'] = action.payload.value;
							//@ts-ignore
						} else inOutFlow['newResult'] = undefined;
					}
				}
			});
		},
		setUserAccepted(state, action) {
			state.userAccepted = action.payload;
		}
	}
});

export default reportsSlice;

export const reportsActions = reportsSlice.actions;

export const { setUserAccepted, setReportStatus } = reportsActions;

export const selectNamecheck = (state: RootState) => state.reports.namecheck;
export const selectScore = (state: RootState) => state.reports.score;
export const selectLights = (state: RootState) => state.reports.lights;
export const selectReportStatus = (state: RootState) => state.reports.reportStatus;
