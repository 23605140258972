import { AppDispatch } from '../store';
import { initializeSession } from './init-session';
import { loadCustomizations } from './init-customizations';
import { handleError } from '../slices/errors.slice';
import { EnumErrorType } from '../../types/ErrorsObject';
import { AxiosError } from 'axios';

export function initializeSessionAndCustomizations(token: string) {
	return async (dispatch: AppDispatch) => {
		const [customizations, session] = await Promise.allSettled([
			dispatch(loadCustomizations(token)),
			dispatch(initializeSession(token))
		]);

		if (customizations.status === 'rejected') {
			const error = customizations.reason;
			dispatch(processCustomizationsError(error));
			throw error;
		}

		if (session.status === 'rejected') {
			const error = session.reason;
			dispatch(processSessionInitError(error));
			throw error;
		}
	};
}

function processCustomizationsError(error: any) {
	return (dispatch: AppDispatch) => {
		dispatch(handleError(error, EnumErrorType.APP_ERROR));
	};
}

function processSessionInitError(error: any) {
	return (dispatch: AppDispatch) => {
		const errorType = getSessionInitializationErrorType(error);
		dispatch(handleError(error, errorType));
	};
}

function getSessionInitializationErrorType(error: any) {
	if (isCheckExpirationError(error)) {
		return EnumErrorType.CHECK_EXPIRED;
	}

	if (isValidationTokenExpiredError(error)) {
		return EnumErrorType.VALIDATION_TOKEN_NO_LONGER_VALID_ERROR;
	}

	return EnumErrorType.APP_ERROR;
}

function isCheckExpirationError(error: object) {
	return (
		error instanceof AxiosError &&
		error.response &&
		error.response.status === 401 &&
		error.response.data === 'Check token expired'
	);
}

function isValidationTokenExpiredError(error: object) {
	return (
		error instanceof AxiosError &&
		error.response &&
		error.response.status === 401 &&
		error.response.data === 'Your verification token has expired'
	);
}
