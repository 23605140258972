import { useDispatch } from 'react-redux';
import Modal from '../custom/Modal';
import Close from '../../assets/images/icons/close.svg';
import React from 'react';
import { ModalEnum, uiActions } from '../../app/slices/ui.slice';
import XS2ATermsModalCustomization from '../customizations/XS2ATermsModalCustomization';

const XS2ATermsModal = () => {
	const dispatch = useDispatch();

	const handleOnCloseModal = () => {
		dispatch(uiActions.toggleModal(ModalEnum.XS2ATERMS_MODAL));
	};
	return (
		<Modal onClose={handleOnCloseModal}>
			<div className="modal-actions">
				<div className="modal-actions-close" onClick={handleOnCloseModal}>
					<img className="modal-close" src={Close} alt="Close" />
				</div>
			</div>
			<XS2ATermsModalCustomization />
		</Modal>
	);
};

export default XS2ATermsModal;
