import { setReportStatus, setUserAccepted } from '../slices/report.slice';
import { checkReportAPI, ErrorResponse } from '../../services/CheckReportService';
import { setBankAccount } from '../slices/bank.slice';
import { Check, Persona } from '../../check/types';
import { AxiosResponse } from 'axios';
import { setFlags } from '../slices/flags.slice';
import { setFinalStepType } from '../slices/ui.slice';
import { handleError } from '../slices/errors.slice';
import { parseKontoData } from './report';
import { saveMetadata } from '../slices/metadata.slice';
import { sessionActions } from '../slices/session.slice';
import { AppDispatch } from '../store';
import { personaActions, setPersonaInfo } from '../slices/persona.slice';
import { getFincredibleReport } from '../../check/utils';
import { initializeCurrentStep } from './init-current-step';
import { FINcredbileReport } from '../../check/report';

const INITIALIZATION_STARTED = '@STATE_INIT_STARTED';
const INITIALIZATION_SUCCEEDED = '@STATE_INIT_SUCCEEDED';
const INITIALIZATION_FAILED = '@STATE_INIT_FAILED';

type CheckResponse = AxiosResponse<Check> | AxiosResponse<ErrorResponse>;
type CheckErrorResponse = AxiosResponse<ErrorResponse>;

/**
 *	Initialize application state:
 *  	1. initialize application state based on check report response
 *  	2. initialize current step based on derived application state
 * */
export const initializeState = () => {
	return async (dispatch: AppDispatch) => {
		dispatch({ type: INITIALIZATION_STARTED });
		try {
			const response: CheckResponse = await checkReportAPI.fetchReport();

			if (isErrorResponse(response)) {
				const errors = response.data.errors;
				const error = new Error(errors);
				dispatch(handleError(error));
				return;
			}

			dispatch(initializeStore(response));
			dispatch(initializeCurrentStep());
			dispatch({ type: INITIALIZATION_SUCCEEDED });
		} catch (error: any) {
			dispatch(handleError(error));
			dispatch({ type: INITIALIZATION_FAILED });
			throw error;
		}
	};
};

export const isErrorResponse = (response: CheckResponse): response is CheckErrorResponse => {
	return (response.data as ErrorResponse).errors !== undefined;
};

/**
 *	Initialize application state (redux store) based on check report response.
 * */
export const initializeStore = (response: AxiosResponse<Check>) => {
	return (dispatch: AppDispatch) => {
		const check = response.data;

		const legacyToken = response.headers['legacy-token'];
		dispatch(sessionActions.setLegacyToken(legacyToken));
		dispatch(saveCommonCheckData(check));

		if (isCheckAccepted(check)) {
			return dispatch(saveAcceptedCheckData(check));
		}

		if (isCheckDiscarded(check)) {
			return dispatch(saveDiscardedCheckData(check));
		}

		dispatch(saveActiveCheckData(check));
	};
};

const isCheckAccepted = (check: Check): boolean => {
	const report = getFincredibleReport(check);
	const accepted = report.userAccepted;
	return accepted === true;
};

const isCheckDiscarded = (check: Check): boolean => {
	const report = getFincredibleReport(check);
	const accepted = report.userAccepted;
	return accepted === false;
};

const saveAcceptedCheckData = (check: Check) => {
	return (dispatch: AppDispatch) => {
		if (isPureEmailValidationCheck(check)) {
			dispatch(setUserAccepted(true));
			dispatch(setReportStatus('ACCEPTED'));
			dispatch(setFinalStepType('EMAIL_VALIDATION_FINISHED'));
			return;
		}

		dispatch(setUserAccepted(true));
		dispatch(setReportStatus('ACCEPTED'));
		dispatch(setFinalStepType('ACCEPTED_RELOADED'));
	};
};

const saveDiscardedCheckData = (check: Check) => {
	return (dispatch: AppDispatch) => {
		dispatch(setUserAccepted(false));
		dispatch(setReportStatus('DISCARDED'));
		dispatch(setFinalStepType('DISCARDED_RELOADED'));
	};
};

const isPureEmailValidationCheck = (check: Check): boolean => {
	const { report } = getFincredibleReport(check);

	if (!report || report.length !== 1) return false;

	const criterion = report[0];
	return criterion.code === 'EMAIL_VALIDATION';
};

const saveCommonCheckData = (check: Check) => {
	return (dispatch: AppDispatch) => {
		const { flags, metadata, persona } = check;

		dispatch(setFlags(flags));
		dispatch(saveMetadata(metadata));
		dispatch(setPersonaInfo(persona));

		if (persona && isPersonaDataEditable(persona)) {
			dispatch(personaActions.setCanEditData(true));
		}
	};
};

const saveActiveCheckData = (check: Check) => {
	return (dispatch: AppDispatch) => {
		const report = getFincredibleReport(check);

		if (isReportReady(report)) {
			dispatch(parseKontoData(report));
		}

		if (hasBankAccount(check)) {
			const bankAccount = check.bankAccount;
			dispatch(setBankAccount(bankAccount));
		}

		const status = report.status;
		dispatch(setReportStatus(status));
	};
};

const hasBankAccount = (check: Check): check is Required<Pick<Check, 'bankAccount'>> & Check => {
	const bankAccount = check.bankAccount;
	return bankAccount !== undefined && bankAccount !== null;
};

const isReportReady = (report: FINcredbileReport): boolean => {
	return report.status === 'READY';
};

const isPersonaDataEditable = (persona: Persona) => {
	const { firstName, lastName, dateOfBirth, country } = persona;

	return !firstName || !lastName || !dateOfBirth || !country;
};
