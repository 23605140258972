import Modal from '../custom/Modal';
import Close from '../../assets/images/icons/close.svg';
import { useDispatch } from 'react-redux';
import React from 'react';
import { ModalEnum, uiActions } from '../../app/slices/ui.slice';
import ConsentModalCustomization from '../customizations/ConsentModalCustomization';

const ConsentModal = () => {
	const dispatch = useDispatch();

	const handleOnCloseModal = () => {
		dispatch(uiActions.toggleModal(ModalEnum.CONSENT_MODAL));
	};

	return (
		<Modal onClose={handleOnCloseModal}>
			<div className="modal-actions">
				<div className="modal-actions-close" onClick={handleOnCloseModal}>
					<img className="modal-close" src={Close} alt="Close" />
				</div>
			</div>
			<ConsentModalCustomization />
		</Modal>
	);
};

export default ConsentModal;
