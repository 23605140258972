import useCustomization from '../../hooks/useCustomization';
import React from 'react';
import { CustomizationKey } from '../../app/slices/customizations.slice';
import ModalHyperlinkMarkdown from '../custom/ModalHyperlinkMarkdown';
import useMDLinkEventHandler from '../../hooks/useMDLinkEventHandler';

const BLANK_CHECK_CONSENT_TEXT_KEY: CustomizationKey = 'BLANK_CHECK_CONSENT_TEXT';

export const BlankCheckEmailInputConsentCustomization = () => {
	const textCustomization = useCustomization(BLANK_CHECK_CONSENT_TEXT_KEY);
	const handleMDLinkClick = useMDLinkEventHandler();

	return <ModalHyperlinkMarkdown content={textCustomization} handleMDLinkClick={handleMDLinkClick} />;
};
