import React from 'react';
import { ICheckItem } from '../../types/Reports';
import Modal from '../custom/Modal';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useCriteria } from '../hooks/useCriteria';
import ModalClose from './ModalClose';
import CriteriaExplanationContainer from './CriteriaExplanationContainer';
import { ModalEnum, selectSelectedCheckItem, uiActions } from '../../app/slices/ui.slice';

export const CheckItemInfoModal = () => {
	const selectedCheckItem = useAppSelector(selectSelectedCheckItem) as ICheckItem;
	// custom hook
	const [title, text] = useCriteria(selectedCheckItem);

	const dispatch = useAppDispatch();

	const handleOnCloseModal = () => {
		dispatch(uiActions.toggleModal(ModalEnum.CODE_INFO_MODAL));
	};

	if (!selectedCheckItem) {
		return (
			<Modal onClose={handleOnCloseModal}>
				<div>
					<p>Invalid code</p>
					<span>There is no information available about this code.</span>
				</div>
			</Modal>
		);
	}

	return (
		<Modal onClose={handleOnCloseModal}>
			<ModalClose onClose={handleOnCloseModal} />
			<CriteriaExplanationContainer title={title} content={text} />
		</Modal>
	);
};

export default CheckItemInfoModal;
