import { AxiosResponseInterceptor } from './types';
import { HttpHeader, HttpStatus } from '../constants/http';
import store from '../app/store';
import { selectLongTermJWT, sessionActions } from '../app/slices/session.slice';
import { BaseService } from './axios';
import { AxiosError, AxiosResponse } from 'axios';

export const AuthorizationRefreshInterceptor: AxiosResponseInterceptor = {
	onRejected: async (error: any) => {
		if (!isAuthorizationTokenExpired(error)) throw error;

		try {
			const longTermToken = getLongTermToken();
			const token = await fetchRefreshedShortTermToken(longTermToken);
			store.dispatch(sessionActions.setShortTermJWT(token));
		} catch (err) {
			throw err;
		}
	}
};

const isAuthorizationTokenExpired = (error: any): boolean => {
	const response = error.response;
	return response && response.status === HttpStatus.ACCESS_FORBIDDEN;
};

const getLongTermToken = () => {
	const token = selectLongTermJWT(store.getState());
	if (token === '') throw new Error('Long term token not defined inside request authorization refresh interceptor');
	return token;
};

const fetchRefreshedShortTermToken = async (token: string) => {
	const response = await BaseService.request({
		method: 'POST',
		url: '/check/request',
		headers: {
			[HttpHeader['Authorization']]: token
		}
	});

	if (!isTokenRefreshedResponse(response)) {
		throw new Error(`Received unsupported response on token refresh: ${response.status}`);
	}

	return getRefreshedToken(response);
};

const isTokenRefreshedResponse = (response: AxiosResponse): boolean => {
	return response.status !== HttpStatus.RESET_CONTENT;
};

const getRefreshedToken = (response: AxiosResponse) => {
	const headers = response.headers;
	const token = headers[HttpHeader['x-check-token']];
	if (!token) throw new Error('Refresh short term token not defined on HTTP 205 Reset');
	return token;
};

export const GatewayErrorInterceptor: AxiosResponseInterceptor = {
	onRejected: (error: AxiosError): Promise<never> => {
		if (error.response && isGatewayResponseError(error.response)) {
			return Promise.reject(Object.assign(new Error('Gateway error'), { status: error.response.status }));
		}
		return Promise.reject(error);
	}
};

const isGatewayResponseError = (response: AxiosResponse): boolean => {
	const status = response.status;
	return (
		status === HttpStatus.BAD_GATEWAY ||
		status === HttpStatus.SERVICE_UNAVAILABLE ||
		status === HttpStatus.GATEWAY_TIMEOUT
	);
};
