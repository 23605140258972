import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import GradientEmailIcon from '../../svg/GradientEmailIcon';
import { useTheme } from '@mui/material';
import { personaActions, selectPersonaEmail } from '../../../app/slices/persona.slice';
import { EnumStepsKeys } from '../../../types/constants';
import { uiActions } from '../../../app/slices/ui.slice';
import TextCustomization from '../../customizations/TextCustomization';
import {
	BlankCheckEmailSentTitleCustomization,
	BlankCheckEmailSentSubsectionCustomization
} from '../../customizations/BlankCheckEmailSentCustomizations';
import { CustomizationKey } from '../../../app/slices/customizations.slice';

const BLANK_CHECK_EMAIL_SENT_PAGE_TITLE_KEY: CustomizationKey = 'BLANK_CHECK_EMAIL_SENT_PAGE_TITLE';
const BLANK_CHECK_EMAIL_SENT_PAGE_SECTION_KEY: CustomizationKey = 'BLANK_CHECK_EMAIL_SENT_PAGE_SECTION';

const EmailSentStep = () => {
	const dispatch = useAppDispatch();
	const email = useAppSelector(selectPersonaEmail);
	const theme = useTheme();

	const style = {
		mainColor: theme.palette.primary.main,
		secondaryColor: theme.palette.secondary.main
	};

	const handleReturn = () => {
		dispatch(personaActions.setPersonaEmail(''));
		dispatch(uiActions.setActiveStep(EnumStepsKeys.NO_EMAIL_STEP));
	};

	return (
		<div className="email-step" style={{ marginTop: '80px' }}>
			<div className="email-step-container">
				<MainComponent email={email} style={style} />
			</div>
			<div style={{ textAlign: 'center', fontSize: '16px', color: '#4A4A4A' }}>
				<SecondaryComponent handleReturn={handleReturn} />
			</div>
		</div>
	);
};

export default EmailSentStep;

type MainComponentProps = {
	email: string;
	style: {
		mainColor: string;
		secondaryColor: string;
	};
};

const MainComponent: React.FC<MainComponentProps> = ({ email, style }) => {
	return (
		<React.Fragment>
			<div style={{ marginBottom: '10px' }}>
				<GradientEmailIcon />
			</div>
			<div className="email-step-main-title-style" style={{ color: `${style.mainColor}` }}>
				<TextCustomization customizationKey={BLANK_CHECK_EMAIL_SENT_PAGE_TITLE_KEY} />
			</div>
			<br />
			<div className="email-step-sub-title-style">
				<BlankCheckEmailSentTitleCustomization email={email} />
			</div>
			<br />
		</React.Fragment>
	);
};

type SecondaryComponentProps = {
	handleReturn: () => void;
};

const SecondaryComponent: React.FC<SecondaryComponentProps> = ({ handleReturn }) => {
	return (
		<React.Fragment>
			<br />
			<div className="final-step-award-text">
				<TextCustomization customizationKey={BLANK_CHECK_EMAIL_SENT_PAGE_SECTION_KEY} />
				<br />
				<br />
			</div>{' '}
			<BlankCheckEmailSentSubsectionCustomization onClick={handleReturn} />
		</React.Fragment>
	);
};
