import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { useLegalDocsMarkdownComponents } from '../../hooks/useLegalDocsMarkdownComponents';

interface CustomMarkdownProps {
	content: string;
}

const LegalDocsMarkdown: React.FC<CustomMarkdownProps> = ({ content }) => {
	const customComponents = useLegalDocsMarkdownComponents();

	return (
		<div style={{ width: '100%' }}>
			<Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} components={customComponents}>
				{content}
			</Markdown>
		</div>
	);
};

export default LegalDocsMarkdown;
