import React from 'react';
import { ICheckItem } from '../types/Reports';
import { PlaceholdersRecord } from '../utils/HTMLTemplateParser';

export enum CRITERIA_CODES {
	ACCOUNTHOLDER = 'ACCOUNTHOLDER',
	IBAN = 'IBAN',
	BIC = 'BIC',
	BANKNAME = 'BANKNAME',
	BANKCOUNTRY = 'BANKCOUNTRY',
	ACTUAL_BALANCE = 'ACTUAL_BALANCE',
	DSTI = 'DSTI',
	EMPLOYMENT_STATUS = 'EMPLOYMENT_STATUS',
	EMPLOYER = 'EMPLOYER',
	EMPLOYMENT_NUMBER = 'EMPLOYMENT_NUMBER',
	CASH = 'CASH',
	OVERDRAFT_UTILIZATION = 'OVERDRAFT_UTILIZATION',
	BAD_CREDIT = 'BAD_CREDIT',
	CHARGES = 'CHARGES',
	CB = 'CB',
	GAMBLING = 'GAMBLING',
	ACTUAL_OVERDRAFT = 'ACTUAL_OVERDRAFT',
	MAXFIN = 'MAXFIN',
	OPTIM_DATE = 'OPTIM_DATE',
	REGULAR_INCOME = 'REGULAR_INCOME',
	REGULAR_PAYMENTS = 'REGULAR_PAYMENTS',
	SAVINGS = 'SAVINGS',
	CREDITCARD = 'CREDITCARD',
	SALARY = 'SALARY',
	PENSION = 'PENSION',
	SELFEMPLOYED_INFLOW = 'SELFEMPLOYED_INFLOW',
	HOUSING_BENEFITS = 'HOUSING_BENEFITS',
	HOUSING_SUBSIDY = 'HOUSING_SUBSIDY',
	CHILDCARE_ALLOWANCE = 'CHILDCARE_ALLOWANCE',
	FAMILY_ALLOWANCE = 'FAMILY_ALLOWANCE',
	ALIMONY_INCOME = 'ALIMONY_INCOME',
	INCOMETAX_REFUND = 'INCOMETAX_REFUND',
	UNCLASSIFIED_INFLOW = 'UNCLASSIFIED_INFLOW',
	PHONE_INTERNET = 'PHONE_INTERNET',
	TV_CABLE = 'TV_CABLE',
	OTHER_ENTERTAINMENT_COMMUNICATION = 'OTHER_ENTERTAINMENT_COMMUNICATION',
	CHILD_ALIMONY = 'CHILD_ALIMONY',
	PARTNER_ALIMONY = 'PARTNER_ALIMONY',
	UNCLASSIFIED_ALIMONY = 'UNCLASSIFIED_ALIMONY',
	HOUSING = 'HOUSING',
	UTILITIES = 'UTILITIES',
	MOBILITY = 'MOBILITY',
	INSURANCE = 'INSURANCE',
	CREDIT_AND_LEASING = 'CREDIT_AND_LEASING',
	INVESTMENTS = 'INVESTMENTS',
	LEISURE = 'LEISURE',
	GROCERIES_AND_RESTAURANTS = 'GROCERIES_AND_RESTAURANTS',
	CASH_WITHDRAWAL = 'CASH_WITHDRAWAL',
	SOCIAL_INSURANCE = 'SOCIAL_INSURANCE',
	UNCLASSIFIED_OUTFLOW = 'UNCLASSIFIED_OUTFLOW',
	RMIN = 'RMIN',
	MAX_RENT = 'MAX_RENT',
	LTVR = 'LTVR',
	LIMIT_ADVANCED = 'LIMIT_ADVANCED',
	THRESHOLD = 'THRESHOLD',
	SEVERANCE_PAYMENT = 'SEVERANCE_PAYMENT',
	OVERDRAFT_AVAILABILITY = 'OVERDRAFT_AVAILABILITY',
	ACCOUNT_USAGE = 'ACCOUNT_USAGE',
	HISTORY = 'HISTORY',
	OVERDRAFT = 'OVERDRAFT',
	BALANCE_AT_OPTIM_DATE = 'BALANCE_AT_OPTIM_DATE',
	EMPLOYER_DURATION = 'EMPLOYER_DURATION',
	RENTAL_INCOME = 'RENTAL_INCOME',
	LIMIT = 'LIMIT',
	BALANCEV2 = 'BALANCEV2',
	UNEMPLOYED_INFLOW = 'UNEMPLOYED_INFLOW'
}

export const BANK_ACCOUNT = {
	ACCOUNTHOLDER: CRITERIA_CODES.ACCOUNTHOLDER,
	IBAN: CRITERIA_CODES.IBAN,
	BIC: CRITERIA_CODES.BIC,
	BANKNAME: CRITERIA_CODES.BANKNAME,
	BANKCOUNTRY: CRITERIA_CODES.BANKCOUNTRY
};

export enum SCORE_LIGHT {
	LOW_RISK = 'LOW_RISK',
	MEDIUM_RISK = 'MEDIUM_RISK',
	HIGH_RISK = 'HIGH_RISK'
}

export const NAMECHECK = 'NAMECHECK';

export const KONTOCHECK = {
	ACTUAL_BALANCE: CRITERIA_CODES.ACTUAL_BALANCE,
	DSTI: CRITERIA_CODES.DSTI,
	EMPLOYMENT_STATUS: CRITERIA_CODES.EMPLOYMENT_STATUS,
	EMPLOYER: CRITERIA_CODES.EMPLOYER,
	EMPLOYMENT_NUMBER: CRITERIA_CODES.EMPLOYMENT_NUMBER,
	CASH: CRITERIA_CODES.CASH,
	OVERDRAFT_UTILIZATION: CRITERIA_CODES.OVERDRAFT_UTILIZATION,
	BAD_CREDIT: CRITERIA_CODES.BAD_CREDIT,
	CHARGES: CRITERIA_CODES.CHARGES,
	CB: CRITERIA_CODES.CB,
	GAMBLING: CRITERIA_CODES.GAMBLING,
	ACTUAL_OVERDRAFT: CRITERIA_CODES.ACTUAL_OVERDRAFT,
	MAXFIN: CRITERIA_CODES.MAXFIN,
	OPTIM_DATE: CRITERIA_CODES.OPTIM_DATE,
	REGULAR_INCOME: CRITERIA_CODES.REGULAR_INCOME,
	REGULAR_PAYMENTS: CRITERIA_CODES.REGULAR_PAYMENTS,
	SAVINGS: CRITERIA_CODES.SAVINGS,
	CREDITCARD: CRITERIA_CODES.CREDITCARD,
	RMIN: CRITERIA_CODES.RMIN,
	MAX_RENT: CRITERIA_CODES.MAX_RENT,
	LTVR: CRITERIA_CODES.LTVR,
	LIMIT_ADVANCED: CRITERIA_CODES.LIMIT_ADVANCED,
	THRESHOLD: CRITERIA_CODES.THRESHOLD,
	OVERDRAFT_AVAILABILITY: CRITERIA_CODES.OVERDRAFT_AVAILABILITY,
	ACCOUNT_USAGE: CRITERIA_CODES.ACCOUNT_USAGE,
	HISTORY: CRITERIA_CODES.HISTORY,
	OVERDRAFT: CRITERIA_CODES.OVERDRAFT,
	BALANCE_AT_OPTIM_DATE: CRITERIA_CODES.BALANCE_AT_OPTIM_DATE,
	EMPLOYER_DURATION: CRITERIA_CODES.EMPLOYER_DURATION,
	LIMIT: CRITERIA_CODES.LIMIT,
	BALANCEV2: CRITERIA_CODES.BALANCEV2
};

export const INCOME = {
	SALARY: CRITERIA_CODES.SALARY,
	PENSION: CRITERIA_CODES.PENSION,
	SELFEMPLOYED_INFLOW: CRITERIA_CODES.SELFEMPLOYED_INFLOW
};

export const WELFARE = {
	HOUSING_BENEFITS: CRITERIA_CODES.HOUSING_BENEFITS,
	HOUSING_SUBSIDY: CRITERIA_CODES.HOUSING_SUBSIDY
};

export const INFLOW = {
	INCOME,
	SALARY: CRITERIA_CODES.SALARY,
	PENSION: CRITERIA_CODES.PENSION,
	SELFEMPLOYED_INFLOW: CRITERIA_CODES.SELFEMPLOYED_INFLOW,
	CHILDCARE_ALLOWANCE: CRITERIA_CODES.CHILDCARE_ALLOWANCE,
	FAMILY_ALLOWANCE: CRITERIA_CODES.FAMILY_ALLOWANCE,
	ALIMONY_INCOME: CRITERIA_CODES.ALIMONY_INCOME,
	RENTAL_INCOME: CRITERIA_CODES.RENTAL_INCOME,
	INCOMETAX_REFUND: CRITERIA_CODES.INCOMETAX_REFUND,
	WELFARE,
	HOUSING_BENEFITS: CRITERIA_CODES.HOUSING_BENEFITS,
	HOUSING_SUBSIDY: CRITERIA_CODES.HOUSING_SUBSIDY,
	UNCLASSIFIED_INFLOW: CRITERIA_CODES.UNCLASSIFIED_INFLOW,
	SEVERANCE_PAYMENT: CRITERIA_CODES.SEVERANCE_PAYMENT,
	UNEMPLOYED_INFLOW: CRITERIA_CODES.UNEMPLOYED_INFLOW
};

export const ENTERTAINMENT_COMMUNICATION = {
	PHONE_INTERNET: CRITERIA_CODES.PHONE_INTERNET,
	TV_CABLE: CRITERIA_CODES.TV_CABLE,
	OTHER_ENTERTAINMENT_COMMUNICATION: CRITERIA_CODES.OTHER_ENTERTAINMENT_COMMUNICATION
};

export const ALIMONY = {
	CHILD_ALIMONY: CRITERIA_CODES.CHILD_ALIMONY,
	PARTNER_ALIMONY: CRITERIA_CODES.PARTNER_ALIMONY,
	UNCLASSIFIED_ALIMONY: CRITERIA_CODES.UNCLASSIFIED_ALIMONY
};

export const OUTFLOW = {
	HOUSING: CRITERIA_CODES.HOUSING,
	UTILITIES: CRITERIA_CODES.UTILITIES,
	ENTERTAINMENT_COMMUNICATION,
	PHONE_INTERNET: CRITERIA_CODES.PHONE_INTERNET,
	TV_CABLE: CRITERIA_CODES.TV_CABLE,
	OTHER_ENTERTAINMENT_COMMUNICATION: CRITERIA_CODES.OTHER_ENTERTAINMENT_COMMUNICATION,
	MOBILITY: CRITERIA_CODES.MOBILITY,
	ALIMONY,
	CHILD_ALIMONY: CRITERIA_CODES.CHILD_ALIMONY,
	PARTNER_ALIMONY: CRITERIA_CODES.PARTNER_ALIMONY,
	UNCLASSIFIED_ALIMONY: CRITERIA_CODES.UNCLASSIFIED_ALIMONY,
	INSURANCE: CRITERIA_CODES.INSURANCE,
	CREDIT_AND_LEASING: CRITERIA_CODES.CREDIT_AND_LEASING,
	INVESTMENTS: CRITERIA_CODES.INVESTMENTS,
	LEISURE: CRITERIA_CODES.LEISURE,
	GROCERIES_AND_RESTAURANTS: CRITERIA_CODES.GROCERIES_AND_RESTAURANTS,
	CASH_WITHDRAWAL: CRITERIA_CODES.CASH_WITHDRAWAL,
	SOCIAL_INSURANCE: CRITERIA_CODES.SOCIAL_INSURANCE,
	UNCLASSIFIED_OUTFLOW: CRITERIA_CODES.UNCLASSIFIED_OUTFLOW
};

interface IResultsOrder {
	[key: string]: number;
}

export const ResultsOrderStep: IResultsOrder = {
	ACTUAL_BALANCE: 90,
	DSTI: 100,
	EMPLOYMENT_STATUS: 110,
	EMPLOYER: 120,
	EMPLOYMENT_NUMBER: 130,
	CASH: 140,
	OVERDRAFT_UTILIZATION: 150,
	BAD_CREDIT: 160,
	CHARGES: 170,
	CB: 180,
	GAMBLING: 190,
	ACTUAL_OVERDRAFT: 200,
	MAXFIN: 210,
	OPTIM_DATE: 220,
	REGULAR_INCOME: 230,
	REGULAR_PAYMENTS: 240,
	SAVINGS: 250,
	CREDITCARD: 260,
	SALARY: 270,
	PENSION: 280,
	SELFEMPLOYED_INFLOW: 290,
	CHILDCARE_ALLOWANCE: 300,
	FAMILY_ALLOWANCE: 310,
	ALIMONY_INCOME: 320,
	RENTAL_INCOME: 325,
	INCOMETAX_REFUND: 330,
	HOUSING_BENEFITS: 340,
	HOUSING_SUBSIDY: 350,
	UNCLASSIFIED_INFLOW: 360,
	SEVERANCE_PAYMENT: 361,
	UNEMPLOYED_INFLOW: 362,
	HOUSING: 370,
	UTILITIES: 380,
	PHONE_INTERNET: 390,
	TV_CABLE: 400,
	OTHER_ENTERTAINMENT_COMMUNICATION: 410,
	MOBILITY: 420,
	CHILD_ALIMONY: 430,
	PARTNER_ALIMONY: 440,
	UNCLASSIFIED_ALIMONY: 450,
	INSURANCE: 460,
	CREDIT_AND_LEASING: 470,
	INVESTMENTS: 480,
	LEISURE: 490,
	GROCERIES_AND_RESTAURANTS: 500,
	CASH_WITHDRAWAL: 510,
	SOCIAL_INSURANCE: 520,
	UNCLASSIFIED_OUTFLOW: 530
};

//
// INFORMATION TEMPLATES FOR SPECIFIC CODES
//

export type TemplatePlaceholder = {
	name: string;
	value: React.ReactElement | ((citem: ICheckItem) => React.ReactElement);
};

export type CodeInformation = {
	template: string; // template string with specified placeholders using schema _placeholder="<name>" as attribute
	placeholders?: PlaceholdersRecord; // record of placeholders (Key: placeholder name, Value: placeholder)
};

const generic_template = '<span>Hierhin kommt ein spezifischer Text zur Erklärung für Endkunden.</span>';

// Map for each single code with corresponding code information (KONTO CHECK)
export const KontoCheckCodesInformation: Record<string, CodeInformation> = {
	CB: {
		template: generic_template
	},
	CASH: {
		template: generic_template
	},
	CHARGES: {
		template: generic_template
	},
	ACTUAL_OVERDRAFT: {
		template: generic_template
	},
	ACTUAL_BALANCE: {
		template: generic_template
	},
	DSTI: {
		template: generic_template
	},
	BAD_CREDIT: {
		template: generic_template
	},
	CREDITCARD: {
		template: generic_template
	},
	EMPLOYER: {
		template: generic_template
	},
	EMPLOYMENT_NUMBER: {
		template: generic_template
	},
	EMPLOYMENT_STATUS: {
		template: generic_template
	},
	GAMBLING: {
		template: generic_template
	},
	MAXFIN: {
		template: generic_template
	},
	OPTIM_DATE: {
		template: generic_template
	},
	OVERDRAFT_UTILIZATION: {
		template: generic_template
	},
	REGULAR_INCOME: {
		template: generic_template
	},
	REGULAR_PAYMENTS: {
		template: generic_template
	},
	SAVINGS: {
		template: generic_template
	}
} as const;

// Map for each single code with corresponding code information (INFLOW)
export const InflowCheckCodesInformation: Record<string, CodeInformation> = {
	INCOME: {
		template: generic_template
	},
	CHILDCARE_ALLOWANCE: {
		template: generic_template
	},
	FAMILY_ALLOWANCE: {
		template: generic_template
	},
	ALIMONY_INCOME: {
		template: generic_template
	},
	INCOMETAX_REFUND: {
		template: generic_template
	},
	HOUSING_BENEFITS: {
		template: generic_template
	},
	HOUSING_SUBSIDY: {
		template: generic_template
	},
	UNCLASSIFIED_INFLOW: {
		template: generic_template
	}
};

// Map for each single code with corresponding code information (OUTFLOW)
export const OutflowCheckCodesInformation: Record<string, CodeInformation> = {
	HOUSING: {
		template: generic_template
	},
	UTILITIES: {
		template: generic_template
	},
	PHONE_INTERNET: {
		template: generic_template
	},
	TV_CABLE: {
		template: generic_template
	},
	OTHER_ENTERTAINMENT_COMMUNICATION: {
		template: generic_template
	},
	MOBILITY: {
		template: generic_template
	},
	CHILD_ALIMONY: {
		template: generic_template
	},
	PARTNER_ALIMONY: {
		template: generic_template
	},
	UNCLASSIFIED_ALIMONY: {
		template: generic_template
	},
	INSURANCE: {
		template: generic_template
	},
	CREDIT_AND_LEASING: {
		template: generic_template
	},
	INVESTMENTS: {
		template: generic_template
	},
	LEISURE: {
		template: generic_template
	},
	GROCERIES_AND_RESTAURANTS: {
		template: generic_template
	},
	CASH_WITHDRAWAL: {
		template: generic_template
	},
	SOCIAL_INSURANCE: {
		template: generic_template
	},
	UNCLASSIFIED_OUTFLOW: {
		template: generic_template
	} // record of placeholders (Key: placeholder name, Value: placeholder)
};
