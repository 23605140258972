import { CustomizationKey, Language, selectCustomizationForKeyAndLanguage } from '../app/slices/customizations.slice';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../app/hooks';

const useCustomization = (customizationKey: CustomizationKey) => {
	const {
		i18n: { language }
	} = useTranslation();
	const languageString: Language = language as Language;

	return useAppSelector(selectCustomizationForKeyAndLanguage(customizationKey, languageString));
};

export default useCustomization;
