import React from 'react';
import { ReactComponent as DSVGO_Filled } from '../../assets/images/DSGVO_Filled.svg';
import { ReactComponent as ISOFilled } from '../../assets/images/ISO_Filled.svg';
import { ReactComponent as TUV_Filled } from '../../assets/images/TUV_Filled.svg';
import { ReactComponent as SSL_Filled } from '../../assets/images/SSL_Filled.svg';

import auExpertsImg from '../../assets/images/A-CommittedExperts_300dpi.jpg';
import { ReactComponent as CheckIcon } from '../../assets/images/icons/check.svg';
import { ReactComponent as LockIcon } from '../../assets/images/icons/lock.svg';
import { ReactComponent as UserIcon } from '../../assets/images/icons/user.svg';
import { useTheme } from '@mui/material';
import TextCustomization from '../customizations/TextCustomization';
import { CustomizationKey } from '../../app/slices/customizations.slice';

const HELP_PAGE_SECTION_1_TITLE_KEY: CustomizationKey = 'HELP_PAGE_SECTION_1_TITLE';
const HELP_PAGE_SECTION_1_CONTENT_KEY: CustomizationKey = 'HELP_PAGE_SECTION_1_CONTENT';
const HELP_PAGE_SECTION_2_TITLE_KEY: CustomizationKey = 'HELP_PAGE_SECTION_2_TITLE';
const HELP_PAGE_SECTION_2_CONTENT_1_KEY: CustomizationKey = 'HELP_PAGE_SECTION_2_CONTENT_1';
const HELP_PAGE_SECTION_2_CONTENT_2_KEY: CustomizationKey = 'HELP_PAGE_SECTION_2_CONTENT_2';
const HELP_PAGE_SECTION_2_CONTENT_3_KEY: CustomizationKey = 'HELP_PAGE_SECTION_2_CONTENT_3';
const HELP_PAGE_SECTION_3_TITLE_KEY: CustomizationKey = 'HELP_PAGE_SECTION_3_TITLE';
const HELP_PAGE_SECTION_3_CONTENT_1_KEY: CustomizationKey = 'HELP_PAGE_SECTION_3_CONTENT_1';
const HELP_PAGE_SECTION_3_CONTENT_2_KEY: CustomizationKey = 'HELP_PAGE_SECTION_3_CONTENT_2';
const HELP_PAGE_SECTION_3_CONTENT_3_KEY: CustomizationKey = 'HELP_PAGE_SECTION_3_CONTENT_3';

const HelpTab = () => {
	const theme = useTheme();

	const styleColor = {
		mainColor: theme.palette.primary.main
	};

	const kontoCheckStepsContents: CustomizationKey[] = [
		HELP_PAGE_SECTION_2_CONTENT_1_KEY,
		HELP_PAGE_SECTION_2_CONTENT_2_KEY,
		HELP_PAGE_SECTION_2_CONTENT_3_KEY
	];

	const h3Style = { borderBottom: `1px dashed ${styleColor.mainColor}`, justifyItems: 'center' };

	return (
		<div className="help-tab">
			<h3 style={h3Style}>
				<TextCustomization customizationKey={HELP_PAGE_SECTION_1_TITLE_KEY} />
			</h3>
			<div className="help-tab-span">
				<TextCustomization customizationKey={HELP_PAGE_SECTION_1_CONTENT_KEY} />
			</div>

			<h3 style={h3Style}>
				<TextCustomization customizationKey={HELP_PAGE_SECTION_2_TITLE_KEY} />
			</h3>
			<div className="help-steps">
				<div className="full-size-help-container">
					{kontoCheckStepsContents.map((key, index) => (
						<HelpStepComponent
							numeration={index + 1}
							customizationKey={kontoCheckStepsContents[index]}
							key={index}
						/>
					))}
				</div>
			</div>

			<div className="help-organizations">
				<div className="org-2-container">
					<div className="org">
						<DSVGO_Filled fill={styleColor.mainColor} />
					</div>
					<div className="org">
						<ISOFilled fill={styleColor.mainColor} />
					</div>
				</div>
				<div className="org-2-container">
					<div className="org">
						<TUV_Filled fill={styleColor.mainColor} />
					</div>
					<div className="org">
						<SSL_Filled fill={styleColor.mainColor} />
					</div>
				</div>
				<div className="help-au-experts">
					<img src={auExpertsImg} alt="Experts" />
				</div>
			</div>

			<h3 style={h3Style}>
				<TextCustomization customizationKey={HELP_PAGE_SECTION_3_TITLE_KEY} />
			</h3>
			<div className="help-beneficial">
				<div className="card">
					<div style={{ padding: '6px' }}>
						<CheckIcon fill={styleColor.mainColor} />
					</div>
					<div className="content" style={{ padding: '2px' }}>
						<TextCustomization customizationKey={HELP_PAGE_SECTION_3_CONTENT_1_KEY} />
					</div>
				</div>
				<div className="card">
					<div style={{ padding: '6px' }}>
						<LockIcon fill={styleColor.mainColor} />
					</div>
					<div className="content" style={{ padding: '2px' }}>
						<TextCustomization customizationKey={HELP_PAGE_SECTION_3_CONTENT_2_KEY} />
					</div>
				</div>
				<div className="card">
					<div style={{ padding: '6px' }}>
						<UserIcon fill={styleColor.mainColor} />
					</div>
					<div className="content" style={{ padding: '2px' }}>
						<TextCustomization customizationKey={HELP_PAGE_SECTION_3_CONTENT_3_KEY} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default HelpTab;

type HelpStepComponentProps = {
	numeration: number;
	customizationKey: CustomizationKey;
};

export const HelpStepComponent = ({ numeration, customizationKey }: HelpStepComponentProps) => {
	const theme = useTheme();
	const style = { color: theme.palette.primary.main, border: `4px solid ${theme.palette.primary.main}` };
	return (
		<div className="help-step" style={{ backgroundColor: `${theme.palette.primary.lighter}80` }}>
			<div className="help-step-numeration">
				<div className="circle" style={style}>
					{numeration}
				</div>
			</div>
			<div className="help-step-content">
				<TextCustomization customizationKey={customizationKey} />
			</div>
		</div>
	);
};
