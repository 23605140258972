import React, { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { EnumSteps } from '../../types/constants';
import { useTheme } from '@mui/material';
import { RootState } from '../../app/store';

const HeaderProgress = () => {
	const activeStep = useSelector((state: RootState) => state.ui.activeStep);
	const theme = useTheme();

	const progressBarStyles = useMemo(() => {
		let width;
		let borderRadius = '';

		switch (activeStep.id) {
			case EnumSteps.ErrorStep:
			case EnumSteps.FinalStep:
				width = 100;
				borderRadius = '5px 5px 5px 5px';
				break;
			case EnumSteps.NoEmailStep:
				width = 50;
				borderRadius = '5px 5px 5px 5px';
				break;
			case EnumSteps.EmailSentStep:
			case EnumSteps.EmailVerifiedStep:
				width = 100;
				borderRadius = '5px 5px 5px 5px';
				break;
			default:
				width = (+activeStep.id + 1) * 16.6667;
				if (width >= 100) {
					borderRadius = '5px 5px 5px 5px';
				}
		}

		return { width: `${width}%`, borderRadius };
	}, [activeStep.id]);

	return (
		<Fragment>
			<div className="header-progress">
				<div className="header-progress-bar" style={{ backgroundColor: `${theme.palette.primary.lighter}` }}>
					<div
						style={{
							width: progressBarStyles.width,
							borderRadius: progressBarStyles.borderRadius,
							backgroundColor: `${theme.palette.primary.light}`
						}}
						className="header-start-bar"
					/>
				</div>
			</div>
		</Fragment>
	);
};

export default HeaderProgress;
