import { useEffect } from 'react';
import useCustomization from '../../hooks/useCustomization';
import { CustomizationKey } from '../../app/slices/customizations.slice';

const useWindowTitle = (customizationKey: CustomizationKey) => {
	const windowTitle = useCustomization(customizationKey);

	useEffect(() => {
		if (windowTitle !== undefined) {
			document.title = windowTitle;
		}
	}, [windowTitle]);
};

export default useWindowTitle;
