import React, { useMemo } from 'react';

export const useFAQMarkdownComponents = () => {
	return useMemo(
		() => ({
			figure: ({ node, ...props }: any) => (
				<figure
					style={{
						margin: '0'
					}}
					{...props}
				/>
			),
			table: ({ node, ...props }: any) => (
				<div style={{ margin: '0', overflowX: 'scroll' }}>
					<table
						style={{
							borderCollapse: 'collapse',
							width: '100%',
							border: '1px solid #ddd',
							overflowX: 'scroll'
						}}
						{...props}
					/>
				</div>
			),
			th: ({ node, ...props }: any) => (
				<th
					style={{
						border: '1px solid #ddd',
						padding: '8px',
						textAlign: 'left',
						backgroundColor: '#eceef4'
					}}
					{...props}
				/>
			),
			td: ({ node, ...props }: any) => (
				<td
					style={{
						border: '1px solid #ddd',
						padding: '8px',
						wordWrap: 'break-word',
						minWidth: '100px'
					}}
					{...props}
				/>
			)
		}),
		[]
	);
};
