import { Menu, MenuItem } from '@mui/material';
import React from 'react';
import { LanguageOption } from './LanguageSelect';
import { Language } from '../../app/slices/customizations.slice';

interface LanguageMenuProps {
	isMenuOpen: boolean;
	onMenuClose: () => void;
	languageOptions: Array<LanguageOption>;
	onLanguageClick: (languageOption: LanguageOption) => void;
	currentlySelectedLanguage: Language;
	anchorElement: null | HTMLElement;
}

const LanguageMenu = ({
	languageOptions,
	isMenuOpen,
	onLanguageClick,
	onMenuClose,
	currentlySelectedLanguage,
	anchorElement
}: LanguageMenuProps) => {
	return (
		<Menu
			id="language-menu"
			open={isMenuOpen}
			anchorEl={anchorElement}
			MenuListProps={{ 'aria-labelledby': 'language-container' }}
			onClose={onMenuClose}
		>
			{languageOptions.map((option) => {
				const isCurrentlySelected = option.lang === currentlySelectedLanguage;

				return (
					<MenuItem
						onClick={() => onLanguageClick(option)}
						key={option.lang}
						style={{ background: '#fff' }}
						className={`language-menu-item ${isCurrentlySelected && 'selected-language-item'}`}
					>
						<img className="language-flag" src={option.src} alt="Language" />
						{option.label}
					</MenuItem>
				);
			})}
		</Menu>
	);
};

export default LanguageMenu;
