import React from 'react';
import Modal from '../custom/Modal';
import closeIcon from '../../assets/images/icons/close.svg';
import { useDispatch } from 'react-redux';
import CheckAccordionRow from '../steps/CheckAccordionRow';
import CheckAccordionItem from '../steps/CheckAccordionItem';
import FormattedNumberInput from '../custom/FormattedNumberInput';
import StepButton from '../steps/StepButton';
import { getInOutFlowItemStatus } from '../../types/Reports';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../app/hooks';
import { TextField, useTheme } from '@mui/material';
import { getCriteriaTitleWithCodeOnly } from '../hooks/useCriteriaTitle';
import { ModalEnum, uiActions } from '../../app/slices/ui.slice';
import { reportsActions } from '../../app/slices/report.slice';
import { personaActions } from '../../app/slices/persona.slice';
import { RESULTS_PAGE_PREFIX } from '../../utils/translationPagePrefix';
import { CurrencyFormatter } from '../../utils/CurrencyTextFormatter';

const TRANSLATION_PREFIX = `${RESULTS_PAGE_PREFIX}.modals`;

const enterOwnInformationTranslationKey = `${TRANSLATION_PREFIX}.enter your own information (EUR, per month)`;
const enterCommentTranslationKey = `${TRANSLATION_PREFIX}.enter a comment`;
const informationTranslationKey = `${TRANSLATION_PREFIX}.information/proof of your self-declaration`;
const householdsTranslationKey = `${RESULTS_PAGE_PREFIX}.households`;
const commentTranslationKey = `${TRANSLATION_PREFIX}.comment`;
const saveTranslationKey = `${TRANSLATION_PREFIX}.save`;

const ReportCommentWithValueModal = () => {
	const dispatch = useDispatch();

	const { t } = useTranslation();
	const theme = useTheme();
	const modalTitle = useAppSelector((state) => state.ui.modalTitle);
	const translatedTitle = getCriteriaTitleWithCodeOnly(modalTitle, t).props.children;
	const reportCommentCode = useAppSelector((state) => state.ui.reportCommentCode);
	const reportCommentValue = useAppSelector((state) => state.ui.reportCommentValue);
	const reportCommentText = useAppSelector((state) => state.ui.reportCommentText);

	const check = useAppSelector((state) => {
		return state.reports.household.find((element: any) => element.code === reportCommentCode);
	});

	let result = check ? (check.newResult !== undefined ? check.newResult : check.result) : undefined;
	if (!result) result = '';

	const handleSetReportComment = (event: any) => {
		dispatch(uiActions.setReportCommentText(event.target.value));
	};

	const handleSetReportCommentValueString = (input: string) => {
		dispatch(uiActions.setReportCommentValue(input));
	};

	const handleOnCloseModal = () => {
		dispatch(uiActions.toggleModal(ModalEnum.REPORT_COMMENT_WITH_VALUE_MODAL));
	};

	const saveToStoreAssessment = () => {
		dispatch(
			reportsActions.setCommentForHouseholdCheckReport({
				code: reportCommentCode,
				comment: reportCommentText,
				value: reportCommentValue
			})
		);
		dispatch(
			personaActions.setUserAssessmentArray({
				code: reportCommentCode,
				comment: reportCommentText,
				value: reportCommentValue
			})
		);
		dispatch(uiActions.toggleModal(ModalEnum.REPORT_COMMENT_WITH_VALUE_MODAL));
	};

	const handleSaveReportComment = () => {
		saveToStoreAssessment();
	};

	const styleTextArea = {
		border: '1px solid #969696',
		'& fieldset': {
			border: 'none'
		},
		'&:focus-within': {
			border: `1px solid ${theme.palette.primary.light}`
		}
	};

	return (
		<Modal onClose={handleOnCloseModal}>
			<div className="modal-actions">
				<div className="modal-actions-close" onClick={handleOnCloseModal}>
					<img className="modal-close" src={closeIcon} alt="Close" />
				</div>
			</div>
			<div
				className="modal-title"
				style={{
					padding: 0,
					fontFamily: 'Roboto, sans-serif'
				}}
			>
				<CheckAccordionRow
					openable={false}
					title={t(householdsTranslationKey)}
					style={{ borderBottom: '0', zIndex: '30', padding: 0 }}
					displayComment={false}
					displayInfo={false}
					displayDetails={false}
					soloTitle={true}
				/>
			</div>
			<CheckAccordionItem
				title={translatedTitle}
				subtitle={`EUR ${CurrencyFormatter.formatValueToCurrencyString(result)}`}
				displayDetails={false}
				displayInfo={false}
				displayComment={false}
				style={{ marginBottom: '10px' }}
				status={getInOutFlowItemStatus(check)}
			/>
			<div
				className="modal-title"
				style={{
					transform: 'translateY(10px)'
				}}
			>
				{t(enterOwnInformationTranslationKey)}:
			</div>
			<FormattedNumberInput
				initValue={CurrencyFormatter.formatValueToCurrencyString(reportCommentValue)}
				placeholder={translatedTitle + ' (optional)'}
				className="modal-report-comments-value"
				id={'report-value'}
				onChange={handleSetReportCommentValueString}
			/>
			<div className="modal-title">{t(enterCommentTranslationKey)}:</div>
			<TextField
				sx={styleTextArea}
				multiline
				className="modal-textarea"
				rows={5}
				placeholder={`${t(commentTranslationKey)}` + ' (optional)\r\n' + `${t(informationTranslationKey)}`}
				onChange={handleSetReportComment}
				value={reportCommentText}
			/>
			<StepButton
				style={{ width: '50%' }}
				disabled={false}
				title={t(saveTranslationKey)}
				onClick={handleSaveReportComment}
			/>
		</Modal>
	);
};

export default ReportCommentWithValueModal;
