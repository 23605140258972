import { useTheme } from '@mui/material';
import React from 'react';
import TextCustomization from '../../customizations/TextCustomization';
import { CustomizationKey } from '../../../app/slices/customizations.slice';

interface HeaderProps {
	customizationKey: CustomizationKey;
}

export const Header = ({ customizationKey }: HeaderProps) => {
	const theme = useTheme();

	const style = { color: `${theme.palette.primary.main}` };
	return (
		<div className="final-step-award-title" style={style}>
			<TextCustomization customizationKey={customizationKey} />
		</div>
	);
};

export default Header;
