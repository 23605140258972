import { TFunction, useTranslation } from 'react-i18next';
import { PERSONAL_INFO_PAGE_PREFIX } from '../../../utils/translationPagePrefix';
import React from 'react';
import { Persona } from '../../../types/Persona';

const PERSONA_INFORMATION_TRANSLATION_KEY = `${PERSONAL_INFO_PAGE_PREFIX}.header.personal information`;

export interface UserInformationContainerProps {
	persona: Persona;
}

export const UserInformationContainer: React.FC<UserInformationContainerProps> = ({ persona }) => {
	const { t } = useTranslation();
	const name = getPersonaName(persona);
	const secondary = usePersonaSecondaryInformation(persona);

	if (!name) {
		const text = t(PERSONA_INFORMATION_TRANSLATION_KEY);
		return (
			<div className="user-info-container">
				<div className="user-info-text-complete">{text}</div>
			</div>
		);
	}

	const SecondaryText = secondary && <div className="user-info-text-complete">{secondary}</div>;
	return (
		<div className="user-info-container">
			<div className="user-info-text">{name}</div>
			{SecondaryText}
		</div>
	);
};

const getPersonaName = (persona: Persona): string | undefined => {
	const { firstName, lastName } = persona;

	if (!firstName && !lastName) return undefined;

	const arr = [firstName, lastName];
	return arr.filter(Boolean).join(' ');
};

const usePersonaSecondaryInformation = (persona: Persona): string | undefined => {
	const { t } = useTranslation();
	const {
		dateOfBirth,
		residence: { country }
	} = persona;

	if (!dateOfBirth && !country) return undefined;

	const information = [dateOfBirth, getCountry(country, t)];
	return information.filter(Boolean).join(', ');
};

const getCountry = (country: Persona['residence']['country'], t: TFunction): string | undefined => {
	if (!country) return undefined;
	return t(`countries.${country}`);
};
