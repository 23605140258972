import React from 'react';
import { useAppSelector } from '../../app/hooks';
import ResultsSummary from './ResultsSummary';
import PersonalCheckInfo from './PersonalCheckInfo';
import { EnumResultsPageType } from '../../types/Reports';

type ResultsSummaryProps = {
	init: () => void;
};

const ResultsWrapper = ({ init }: ResultsSummaryProps) => {
	const pageType = useAppSelector((state) => state.ui.checkResultViewType);

	const ResultsComponent = React.useMemo(() => {
		switch (pageType) {
			case EnumResultsPageType.RESULTS_SUMMARY:
				return <ResultsSummary init={init} />;
			case EnumResultsPageType.RESULTS_VIEW:
				return <PersonalCheckInfo onEnableNextButton={init} />;
			default:
				return <></>;
		}
	}, [pageType]);

	return <div>{ResultsComponent}</div>;
};

export default ResultsWrapper;
