import useCustomization from '../../hooks/useCustomization';
import { CustomizationKey } from '../../app/slices/customizations.slice';
import Markdown from 'react-markdown';

interface TextCustomizationProps {
	customizationKey: CustomizationKey;
}

const TextCustomization = ({ customizationKey }: TextCustomizationProps) => {
	const textCustomization = useCustomization(customizationKey);

	return <Markdown>{textCustomization}</Markdown>;
};

export default TextCustomization;
