import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

export let translations_loaded = false;

export const i18nInstance = i18n
	.use(HttpApi) // browser http backend plugin for loading translation files dynamically and lazy
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {},
		fallbackLng: ['de', 'en'],
		supportedLngs: ['en', 'de'],
		nonExplicitSupportedLngs: false,
		debug: false, // enable only in development
		load: 'languageOnly',
		preload: ['de'],
		cleanCode: true,
		returnEmptyString: false, // allow empty strings
		partialBundledLanguages: true, // allows some resources to be lazy loaded using backend connector,
		backend: {
			loadPath: './locales/{{lng}}/{{ns}}.json',
			crossDomain: false
		},
		react: {
			bindI18n: 'languageChanged', // which event triggers rerender
			useSuspense: false,
			transSupportBasicHtmlNodes: true,
			transKeepBasicHtmlNodesFor: ['br', 'p', 'i', 'u', 'strong', 'b', 'span']
		}
	})
	.then(() => {
		translations_loaded = true;
	});

export default i18nInstance;
