import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

const options = {
	info: {
		beacon: 'bam.eu01.nr-data.net',
		errorBeacon: 'bam.eu01.nr-data.net',
		licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
		applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID
	},
	loader_config: {
		accountID: process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID,
		trustKey: process.env.REACT_APP_NEW_RELIC_TRUST_KEY,
		agentID: process.env.REACT_APP_NEW_RELIC_AGENT_ID,
		licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
		applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID
	}
};

const NewRelicAgent = new BrowserAgent(options);
export default NewRelicAgent;
