import { AppDispatch } from '../store';
import { tokenService } from '../../services/TokenService';
import { AxiosResponse } from 'axios';
import { sessionActions } from '../slices/session.slice';
import { metadataActions } from '../slices/metadata.slice';
import { Configuration, configurationActions } from '../slices/configuration.slice';
import jwt_decode from 'jwt-decode';

const TOKEN_HEADER = 'x-check-token';
const TOKEN_REFRESHED_STATUS_CODE = 205;

const SESSION_INIT_STARTED = '@SESSION_INIT_STARTED';
const SESSION_INIT_SUCCEEDED = '@SESSION_INIT_SUCCEEDED';
const SESSION_INIT_FAILED = '@SESSION_INIT_FAILED';

export function initializeSession(token: string) {
	return async (dispatch: AppDispatch) => {
		dispatch({ type: SESSION_INIT_STARTED });
		try {
			dispatch(initializeConfiguration(token));

			const response = await tokenService.fetchAuthorizationToken(token);
			await dispatch(processTokenResponse(response, token));

			dispatch({ type: SESSION_INIT_SUCCEEDED });
		} catch (error: any) {
			dispatch({ type: SESSION_INIT_FAILED });
			throw error;
		}
	};
}

const initializeConfiguration = (token: string) => {
	return (dispatch: AppDispatch) => {
		dispatch(metadataActions.saveColorFromJWT(token));
		dispatch(configurationActions.saveConfigurationViaToken(token));
	};
};

const processTokenResponse = (response: AxiosResponse, mainsToken: string) => {
	return async (dispatch: AppDispatch) => {
		const token = response.headers[TOKEN_HEADER];
		const isLongTermToken = isTokenLongTerm(token);

		if (isLongTermToken) {
			dispatch(sessionActions.setLongTermJWT(token));
			dispatch(sessionActions.setValidationJWT(mainsToken));
			const getShortTermTokenResponse = await tokenService.fetchAuthorizationToken(token);
			dispatch(processShortTermToken(getShortTermTokenResponse));
		} else {
			dispatch(sessionActions.setLongTermJWT(mainsToken));
			dispatch(processShortTermToken(response));
		}
	};
};

const isTokenLongTerm = (token: string) => {
	const decodedJWT = jwt_decode(token) as Partial<Configuration>;
	return decodedJWT.hasOwnProperty('bankRedirectMethod');
};

const processShortTermToken = (response: AxiosResponse) => {
	return (dispatch: AppDispatch) => {
		if (response.status !== TOKEN_REFRESHED_STATUS_CODE) {
			throw new Error('Short term token is not fetched correctly');
		}

		const token = response.headers[TOKEN_HEADER];
		if (!token) throw new Error('Short term token is not fetched correctly');

		dispatch(sessionActions.setShortTermJWT(token));
	};
};
