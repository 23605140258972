import React from 'react';
import { useTheme } from '@mui/material';

type StepButtonProps = {
	disabled: boolean;
	title: string;
	onClick: () => void;
	className?: string;
	style?: object;
};

const StepButton = ({ disabled, title, onClick, className, style }: StepButtonProps) => {
	const theme = useTheme();

	const styleDisabled = disabled
		? {
				backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main}26, ${theme.palette.primary.main}26)`
		  }
		: {};

	return (
		<div className="form__group">
			<button
				id="step-button"
				onClick={onClick}
				className={`${className || ''} step-button-class`}
				disabled={disabled}
				style={{
					...style,
					backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
					...styleDisabled
				}}
			>
				{title}
				<div className="button-wrapper">
					<div className="shine-div" />
				</div>
			</button>
		</div>
	);
};

export default StepButton;
