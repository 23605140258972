import { Step } from './Step';

export enum EnumStepsKeys {
	LOADING_STEP = 'loadingStep',
	FIRST_STEP = 'firstStep',
	SECOND_STEP = 'secondStep',
	BANK_STEP = 'bankStep',
	WAITING_RESULTS = 'waitingResults',
	CHECK_STEP = 'checkStep',
	FINAL_STEP = 'finalStep',
	ERROR_STEP = 'errorStep',
	NO_EMAIL_STEP = 'noEmailStep',
	EMAIL_SENT_STEP = 'emailSentStep',
	EMAIL_VERIFIED_STEP = 'emailVerifiedStep'
}

export const stepsConstant: Step[] = [
	{
		id: '0',
		key: EnumStepsKeys.LOADING_STEP,
		label: 'Loading step',
		isDone: false
	},
	{
		id: '1',
		key: EnumStepsKeys.FIRST_STEP,
		label: 'Personal Information Input',
		isDone: false
	},
	{
		id: '2',
		key: EnumStepsKeys.SECOND_STEP,
		label: 'Personal Consent',
		isDone: false
	},
	{
		id: '3',
		key: EnumStepsKeys.BANK_STEP,
		label: 'Bank Interface',
		isDone: false
	},
	{
		id: '4',
		key: EnumStepsKeys.WAITING_RESULTS,
		label: 'Waiting Analytics Results',
		isDone: false
	},
	{
		id: '5',
		key: EnumStepsKeys.CHECK_STEP,
		label: 'Check Step',
		isDone: false
	},
	{
		id: '6',
		key: EnumStepsKeys.FINAL_STEP,
		label: 'Final Step',
		isDone: false
	},
	{
		id: '7',
		key: EnumStepsKeys.ERROR_STEP,
		label: 'Error Step',
		isDone: false
	},
	{
		id: '8',
		key: EnumStepsKeys.NO_EMAIL_STEP,
		label: 'No Email Step',
		isDone: false
	},
	{
		id: '9',
		key: EnumStepsKeys.EMAIL_SENT_STEP,
		label: 'Email Sent Step',
		isDone: false
	},
	{
		id: '10',
		key: EnumStepsKeys.EMAIL_VERIFIED_STEP,
		label: 'Email Verified Step',
		isDone: false
	}
];

// All possible status of single item
export enum ItemStatus {
	VERIFIED = 1,
	USER_CHANGED,
	UNVERIFIED
}

export const BUTTON_TEXT_VALUES: any = ['', 'next', 'start', 'next', 'complete', 'save'];

export const residenceOptionsSelect = [
	{
		label: 'Österreich',
		value: 'AT'
	},
	{
		label: 'Deutschland',
		value: 'DE'
	}
];

export const ResidenceMap: Map<string, string> = new Map(
	residenceOptionsSelect.map((residence) => {
		return [residence.label, residence.value];
	})
);

export const ResidenceInverseMap: Map<string, string> = new Map(
	residenceOptionsSelect.map((residence) => {
		return [residence.value, residence.label];
	})
);

export const personaEmploymentStatus = [
	{
		label: 'Angestellt',
		value: 'Angestellt'
	},
	{
		label: 'Selbstständig',
		value: 'Selbstständig'
	},
	{
		label: 'Pensionist',
		value: 'Pensionist'
	},
	{
		label: 'Nicht erwerbstätig',
		value: 'Nicht erwerbstätig'
	}
];

export enum EnumSteps {
	LoadingStep = '0',
	PersonalInfoStep = '1',
	PersonalConsentStep = '2',
	BankInterfaceStep = '3',
	WaitingAnalyticsResultsStep = '4',
	PersonalCheckInfo = '5',
	FinalStep = '6',
	ErrorStep = '7',
	NoEmailStep = '8',
	EmailSentStep = '9',
	EmailVerifiedStep = '10'
}

export enum EnumLegalDocs {
	Impressum = 9077,
	AGB = 9044,
	Datenschutz = 9088,
	Einwiligung_Erhe_LONG = 9113,
	Einwiligung_Weiter_LONG = 9116,
	Erhebung_SHORT = 9118,
	Weiter_SHORT = 9120
}

export const pageNumberToDocumentTypeMap: Map<number, EnumLegalDocs> = new Map([
	[9077, EnumLegalDocs.Impressum],
	[9044, EnumLegalDocs.AGB],
	[9088, EnumLegalDocs.Datenschutz],
	[9113, EnumLegalDocs.Einwiligung_Erhe_LONG],
	[9116, EnumLegalDocs.Einwiligung_Weiter_LONG],
	[9118, EnumLegalDocs.Erhebung_SHORT],
	[9120, EnumLegalDocs.Weiter_SHORT]
]);

export enum FinishedCheckState {
	ACCEPTED = 1,
	DISCARDED,
	ACCEPTED_RELOADED,
	DISCARDED_RELOADED,
	EMAIL_VALIDATION_FINISHED
}
