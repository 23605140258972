import React, { useState } from 'react';
import HelpTab from './HelpTab';
import FAQTab from './FAQTab';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

const TRANSLATION_PREFIX = 'help';

const Tabs = () => {
	const [activeTab, setActiveTab] = useState('helpTab');

	const { t } = useTranslation();
	const theme = useTheme();

	const handleHelpTab = () => {
		// update the state to tab1
		setActiveTab('helpTab');
	};
	const handleFAQTab = () => {
		// update the state to tab2
		setActiveTab('faqTab');
	};

	const styleColor = {
		mainColor: theme.palette.primary.main,
		border: `1px solid ${theme.palette.primary.main}`,
		color: theme.palette.primary.main
	};

	const liStyle = (tabType: string) => {
		return {
			background: activeTab === tabType ? styleColor.mainColor : ''
		};
	};

	function MouseOver(event: any) {
		event.target.style.background = styleColor.mainColor;
	}

	function MouseOut(event: any, tabType: string) {
		if (activeTab !== tabType) event.target.style.background = '';
	}

	return (
		<div className="modal-tabs">
			{/* Tab nav */}
			<ul className="modal-tabs-nav" style={styleColor}>
				<li
					className={activeTab === 'helpTab' ? 'active' : ''}
					style={liStyle('helpTab')}
					onMouseOver={MouseOver}
					onMouseOut={(event) => MouseOut(event, 'helpTab')}
					onClick={handleHelpTab}
				>
					{t(`${TRANSLATION_PREFIX}.Help`)}
				</li>
				<li
					className={activeTab === 'faqTab' ? 'active' : ''}
					style={liStyle('faqTab')}
					onMouseOver={MouseOver}
					onMouseOut={(event) => MouseOut(event, 'faqTab')}
					onClick={handleFAQTab}
				>
					FAQs
				</li>
			</ul>
			<div className="modal-outlet">{activeTab === 'helpTab' ? <HelpTab /> : <FAQTab />}</div>
		</div>
	);
};
export default Tabs;
