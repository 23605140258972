import React, { useEffect } from 'react';
import { useTheme } from '@mui/material';
import { CheckCircle } from '../svg/CheckCircle';
import TextCustomization from '../customizations/TextCustomization';
import { CustomizationKey } from '../../app/slices/customizations.slice';

type ResultsSummaryProps = {
	init: () => void;
};

const RESULTS_SUMMARY_COMPLETE_CHECK_TEXT_KEY: CustomizationKey = 'RESULTS_SUMMARY_COMPLETE_CHECK_TEXT';
const RESULTS_SUMMARY_PAGE_TITLE_KEY: CustomizationKey = 'RESULTS_SUMMARY_PAGE_TITLE';

const ResultsSummary = ({ init }: ResultsSummaryProps) => {
	const theme = useTheme();
	useEffect(() => {
		init();
	}, []);

	return (
		<div className="results-summary">
			<div className="results-summary-message-text">
				<CheckCircle />
				<div className="results-summary-title" style={{ color: `${theme.palette.primary.main}` }}>
					<TextCustomization customizationKey={RESULTS_SUMMARY_PAGE_TITLE_KEY} />
				</div>
				<br />
				<div className="results-summary-text">
					{' '}
					<TextCustomization customizationKey={RESULTS_SUMMARY_COMPLETE_CHECK_TEXT_KEY} />
				</div>
				<br />
			</div>
		</div>
	);
};

export default ResultsSummary;
