import { AppDispatch, GetState } from '../store';
import { APP_INIT_FAILED, APP_INIT_STARTED, APP_INIT_SUCCEEDED, selectAppStatus } from '../slices/app.slice';
import { initializeState } from './init-state';
import { initializeSessionAndCustomizations } from './init-session-and-customizations';

export function initializeApp() {
	return async (dispatch: AppDispatch, getState: GetState) => {
		const status = selectAppStatus(getState());

		if (status === 'INITIALIZING' || status === 'INITIALIZED') return;

		dispatch({ type: APP_INIT_STARTED });
		try {
			const token = getCheckToken();
			await dispatch(initializeSessionAndCustomizations(token));
			await dispatch(initializeState());
			dispatch({ type: APP_INIT_SUCCEEDED });
		} catch (error: any) {
			dispatch({ type: APP_INIT_FAILED });
		}
	};
}

function getCheckToken(): string {
	const pathname = window.location.pathname;
	// pathname contains an initial "/" followed by the path of the URL (token)
	return pathname.substring(1);
}
