import React from 'react';
import { InputAdornment, useTheme } from '@mui/material';
import alert_triangle from '../../assets/images/icons/alert-triangle.svg';
import TextField from '@mui/material/TextField';
import { CurrencyFormatter } from '../../utils/CurrencyTextFormatter';

const numbersCharacters = '0123456789';

type FormattedNumberInputProps = {
	initValue?: number | string;
	disabled?: boolean;
	placeholder: string;
	onChange?: (value: string) => void;
	id?: string;
	className?: string;
};

const FormattedNumberInput = ({
	initValue,
	disabled = false,
	placeholder,
	onChange,
	id,
	className,
	...rest
}: FormattedNumberInputProps) => {
	const [value, setValue] = React.useState<string>(initValue ? initValue.toString() : '');
	const [error, _] = React.useState<boolean>(false);
	const theme = useTheme();

	const onBlur = () => {
		if (value === '') return;
		setValue((_prev) => CurrencyFormatter.formatValueToCurrencyString(_prev));
	};

	const onFocus = () => {
		setValue((_prev) => CurrencyFormatter.deformatCurrencyString(_prev));
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		if (newValue.length < value.length) {
			setValue(newValue);
			if (onChange) onChange(newValue);
			return;
		}
		const newCharacter = newValue[newValue.length - 1];
		if (numbersCharacters.indexOf(newCharacter) !== -1) {
			setValue(newValue);
			if (onChange) onChange(newValue);
		}
	};

	const style = {
		border: '1px solid #969696',
		borderRadius: '5px',
		background: 'background: #FFFFFF',
		width: '100%',
		'& .MuiInputLabel-root': {
			color: '#707070'
		},
		'& .Mui-focused': {
			color: '#707070'
		},
		'& .MuiInputBase-root': {
			'& .MuiInputBase-input:focus': {
				border: 'none'
			}
		},
		marginTop: '10px',
		marginBottom: '5px',
		'&:focus-within': {
			border: `1px solid ${theme.palette.primary.light}`
		}
	};

	return (
		<TextField
			id={id}
			variant="filled"
			label={placeholder}
			onBlur={onBlur}
			onChange={handleChange}
			onFocus={onFocus}
			inputMode="text"
			value={value || ''}
			disabled={disabled}
			sx={style}
			className={`controlled-input ${className || ''} txt-field`}
			inputProps={{
				...rest,
				underline: {
					'&&&:before': {
						borderBottom: 'none'
					},
					'&&:after': {
						borderBottom: 'none'
					}
				},
				style: { color: `${error ? '#FF0000' : 'black'}` }
			}}
			InputProps={{
				startAdornment: error && (
					<InputAdornment
						position="start"
						className="input-adornment"
						sx={{
							backgroundColor: '#ffffff',
							borderRadius: '5px'
						}}
					>
						<img src={alert_triangle} alt="Alert" className="input-adornment-image" />
					</InputAdornment>
				)
			}}
			InputLabelProps={{
				style: {
					color: `${error ? '#FF0000' : '#707070'}`,
					fontSize: '18px'
				}
			}}
		/>
	);
};

export default FormattedNumberInput;
