import Modal from '../custom/Modal';
import Close from '../../assets/images/icons/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ModalEnum, uiActions } from '../../app/slices/ui.slice';

const ReportInfoModal = () => {
	const dispatch = useDispatch();
	const modalTitle = useSelector((state: any) => state.ui.modalTitle);

	const handleOnCloseModal = () => {
		dispatch(uiActions.toggleModal(ModalEnum.REPORT_INFO_MODAL));
	};

	return (
		<Modal onClose={handleOnCloseModal}>
			<div className="modal-actions">
				<div className="modal-actions-close" onClick={handleOnCloseModal}>
					<img className="modal-close" src={Close} alt="Close" />
				</div>
			</div>
			<div className="modal-title">
				<h4>{modalTitle}</h4>
			</div>
			<div>
				Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
				labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et
				ea rebum.
			</div>
		</Modal>
	);
};

export default ReportInfoModal;
