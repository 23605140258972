import React from 'react';
import FAQsCustomization from '../customizations/FAQsCustomization';

const FAQTab: React.FC = () => {
	return (
		<div className="faq-tab">
			<div className="accordion">
				<FAQsCustomization />
			</div>
		</div>
	);
};

export default FAQTab;
