import { AppDispatch, GetState } from '../store';
import { selectPersona, selectPersonaEmail } from '../slices/persona.slice';
import { setActiveStep, uiActions } from '../slices/ui.slice';
import { EnumStepsKeys } from '../../types/constants';
import { selectValidationJWT } from '../slices/session.slice';
import { Persona } from '../../types/Persona';
import { completeCheck } from './complete-check';
import { FlagsEnum, selectFlag, selectShowResults } from '../slices/flags.slice';
import { EnumResultsPageType } from '../../types/Reports';
import { ReportSliceState } from '../slices/report.slice';

/**
 *	Initialize current step based on application state (redux store).
 * */
export const initializeCurrentStep = () => {
	return (dispatch: AppDispatch, getState: GetState) => {
		const state = getState();
		const reportStatus = state.reports.reportStatus;

		switch (reportStatus) {
			case 'NO_EMAIL':
				dispatch(initializeEmailInputStep());
				break;
			case 'EMAIL_VALIDATION_PENDING':
				dispatch(initializeEmailVerificationPendingStep());
				break;
			case 'PENDING':
				dispatch(initializePendingStep());
				break;
			case 'PROCESSING':
				dispatch(initializeProcessingStep());
				break;
			case 'READY':
				dispatch(initializeReadyStep());
				break;
			case 'ACCEPTED':
			case 'DISCARDED':
			case 'CANCELLED':
				dispatch(initializeFinalStep());
				break;
			default:
				throw new Error('Received invalid report status: ' + reportStatus);
		}
	};
};

export function initializeEmailInputStep() {
	return (dispatch: AppDispatch, getState: GetState) => {
		const state = getState();
		const email = selectPersonaEmail(state);
		if (email) {
			dispatch(uiActions.setActiveStep(EnumStepsKeys.EMAIL_SENT_STEP));
		} else {
			dispatch(uiActions.setActiveStep(EnumStepsKeys.NO_EMAIL_STEP));
		}
	};
}

export function initializeEmailVerificationPendingStep() {
	return (dispatch: AppDispatch) => {
		dispatch(uiActions.setActiveStep(EnumStepsKeys.EMAIL_SENT_STEP));
	};
}

export function initializePendingStep() {
	return (dispatch: AppDispatch, getState: GetState) => {
		const state = getState();
		const persona = selectPersona(state);
		const validationJWT = selectValidationJWT(state);

		if (validationJWT !== '') {
			dispatch(uiActions.setActiveStep(EnumStepsKeys.EMAIL_VERIFIED_STEP));
		} else {
			const isPrefilled = isPersonaPrefilled(persona as unknown as Persona);
			const step = isPrefilled ? EnumStepsKeys.SECOND_STEP : EnumStepsKeys.FIRST_STEP;
			dispatch(uiActions.setActiveStep(step));
		}
	};
}

export function initializeProcessingStep() {
	return (dispatch: AppDispatch, getState: GetState) => {
		const state = getState();
		const bankProcessFinished = state.ui.bankProcessFinished;

		if (bankProcessFinished) {
			dispatch(uiActions.setActiveStep(EnumStepsKeys.WAITING_RESULTS));
		} else {
			dispatch(uiActions.setActiveStep(EnumStepsKeys.BANK_STEP));
		}
	};
}

export function initializeFinalStep() {
	return (dispatch: AppDispatch) => {
		dispatch(setActiveStep(EnumStepsKeys.FINAL_STEP));
	};
}

export function initializeReadyStep() {
	return (dispatch: AppDispatch, getState: GetState) => {
		const state = getState();
		const reports = state.reports;
		const reportEmpty = isReportEmpty(reports);

		if (reportEmpty) {
			dispatch(completeCheck('accepted'));
			return;
		}

		const showResults = selectShowResults(state);
		const selfAssessment = selectFlag(state, FlagsEnum.ASSESSMENT_USER_INPUT);
		if (showResults) {
			const viewType = selfAssessment ? EnumResultsPageType.RESULTS_VIEW : EnumResultsPageType.RESULTS_SUMMARY;
			dispatch(uiActions.setCheckResultsViewType(viewType));
			dispatch(uiActions.setActiveStep(EnumStepsKeys.CHECK_STEP));
		} else {
			dispatch(uiActions.setActiveStep(EnumStepsKeys.FINAL_STEP));
		}
	};
}

const isPersonaPrefilled = (persona: Persona) => {
	return (
		persona.firstName && persona.lastName && persona.residence && persona.residence.country && persona.dateOfBirth
	);
};

function isReportEmpty(reports: ReportSliceState) {
	const { namecheck, household, kontoCheck } = reports;
	return namecheck === undefined && household.length === 0 && kontoCheck.length === 0;
}
