import Loading from '../../custom/Loading';
import React from 'react';
import TextCustomization from '../../customizations/TextCustomization';
import { CustomizationKey } from '../../../app/slices/customizations.slice';

const TRANSMISSION_STARTED_DISPLAY_TEXT_KEY: CustomizationKey = 'TRANSMISSION_STARTED_DISPLAY_TEXT';

export const AnalyticsLoading = () => {
	return (
		<Loading>
			<TextCustomization customizationKey={TRANSMISSION_STARTED_DISPLAY_TEXT_KEY} />
		</Loading>
	);
};
