import React from 'react';
import { CheckTitle } from './CheckTitle';
import { UserInformation } from './UserInformation';
import { useAppSelector } from '../../../app/hooks';
import { selectPersona } from '../../../app/slices/persona.slice';

export const Header = () => {
	const persona = useAppSelector(selectPersona);

	return (
		<div className="content-header">
			<CheckTitle />
			<UserInformation persona={persona} />
		</div>
	);
};
