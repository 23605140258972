import React from 'react';
import { useAppSelector } from '../../app/hooks';
import InfoModal from './InfoModal';
import ConsentModal from './ConsentModal';
import ReportInfoModal from './ReportInfo';
import ReportCommentModal from './ReportComment';
import ReportCommentWithValueModal from './ReportCommentWithValue';
import PrivacyModal from './PrivacyModal';
import AGBModal from './AGBModal';
import CheckItemInfoModal from './CheckItemInfoModal';
import ImpressumModal from './ImpressumModal';
import XS2APrivacyModal from './XS2APrivacyModal';
import XS2ATermsModal from './XS2ATermsModal';

const ModalAggregate = () => {
	const infoModalIsShown = useAppSelector((state) => state.ui.infoModalIsShown);
	const consentModalIsShown = useAppSelector((state) => state.ui.consentModalIsShown);
	const reportInfoModalIsShown = useAppSelector((state) => state.ui.reportInfoModalIsShown);
	const reportCommentModalIsShown = useAppSelector((state) => state.ui.reportCommentModalIsShown);
	const reportCommentWithValueShown = useAppSelector((state) => state.ui.reportCommentWithValueShown);
	const privacyModalIsShown = useAppSelector((state) => state.ui.privacyModalIsShown);
	const agbModalShown = useAppSelector((state) => state.ui.agbModalShown);
	const impressiumModalIsShown = useAppSelector((state) => state.ui.impressiumModalIsShown);
	const codeInfoModalIsShown = useAppSelector((state) => state.ui.codeInfoModalIsShown);
	const xs2aPrivacyModalIsShown = useAppSelector((state) => state.ui.xs2aPrivacyModalIsShown);
	const xs2aTermsModalIsShown = useAppSelector((state) => state.ui.xs2aTermsModalIsShown);

	return (
		<div>
			{infoModalIsShown && <InfoModal />}
			{consentModalIsShown && <ConsentModal />}
			{reportInfoModalIsShown && <ReportInfoModal />}

			{reportCommentModalIsShown && <ReportCommentModal />}
			{reportCommentWithValueShown && <ReportCommentWithValueModal />}
			{privacyModalIsShown && <PrivacyModal />}
			{agbModalShown && <AGBModal />}
			{codeInfoModalIsShown && <CheckItemInfoModal />}
			{impressiumModalIsShown && <ImpressumModal />}

			{xs2aPrivacyModalIsShown && <XS2APrivacyModal />}
			{xs2aTermsModalIsShown && <XS2ATermsModal />}
		</div>
	);
};

export default ModalAggregate;
