import React from 'react';
import { AnalyticsLoading } from './AnalyticsLoading';
import { useObserveAnalyticsResults } from './useObserveAnalyticsResults';

const WaitingAnalyticsResults = () => {
	useObserveAnalyticsResults();

	return <AnalyticsLoading />;
};

export default WaitingAnalyticsResults;
