import React, { useCallback, useMemo } from 'react';
import { residenceOptionsSelect } from '../../types/constants';
import moment from 'moment';
import StyledInput, { ValidationResult } from '../custom/StyledInput';
import DatePicker from '../custom/DatePicker';
import { SelectOption, StyledSelect } from '../custom/StyledSelect';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import DateConverter, { DateFormat, LimitCheckPeriod } from '../../utils/DateConverter';
import { FlagsEnum, selectFlag } from '../../app/slices/flags.slice';
import { PERSONAL_INFO_PAGE_PREFIX } from '../../utils/translationPagePrefix';
import { personaActions, selectPersona } from '../../app/slices/persona.slice';

const INPUTS_TRANS_PREFIX = `${PERSONAL_INFO_PAGE_PREFIX}.inputs`;

interface InputTitles {
	firstname: string;
	lastname: string;
	country: string;
	dateOfBirth: string;
}

const PersonalInfoInput = (props: any) => {
	const dispatch = useAppDispatch();
	const persona = useAppSelector(selectPersona);
	const editCheckData = useAppSelector((state) => selectFlag(state, FlagsEnum.EDIT_CHECK_DATA));

	const { t, i18n } = useTranslation();

	// translate input titles only on language change
	const {
		firstname: firstnameTitle,
		lastname: lastnameTitle,
		country: countryTitle,
		dateOfBirth: dobTitle
	} = useMemo<InputTitles>(() => {
		const firstname = t(`${INPUTS_TRANS_PREFIX}.firstname`);
		const lastname = t(`${INPUTS_TRANS_PREFIX}.lastname`);
		const country = t(`${INPUTS_TRANS_PREFIX}.country`);
		const dateOfBirth = t(`${INPUTS_TRANS_PREFIX}.dateOfBirth`);

		return { firstname, lastname, country, dateOfBirth };
	}, [i18n.language]);

	const handleChangeFirstName = useCallback(
		(result: ValidationResult) => {
			dispatch(personaActions.setPersonaFirstName(result.value));
			dispatch(personaActions.setIsValidPersonaFirstName(result.isValid));
			props.onValidFormPersonalInfo();
		},
		[dispatch, props]
	);

	const handleChangeLastName = useCallback(
		(result: ValidationResult) => {
			dispatch(personaActions.setPersonaLastName(result.value));
			dispatch(personaActions.setIsValidPersonaLastName(result.isValid));
			props.onValidFormPersonalInfo();
		},
		[dispatch]
	);

	const handleChangeDateOfBirth = useCallback(
		(param: { isValid: boolean; date: Date | null }) => {
			if (param.date !== null && param.isValid) {
				const changeFormatDate = moment(new Date(param.date)).format('YYYY-MM-DD');
				dispatch(personaActions.setPersonaDateOfBirth(changeFormatDate));
				dispatch(personaActions.setIsValidPersonaDateOfBirth(true));
				props.onValidFormPersonalInfo();
			} else dispatch(personaActions.setIsValidPersonaDateOfBirth(false));
		},
		[dispatch, props]
	);

	const translateCountry = (country: string) => {
		return t(`countries.${country}`);
	};

	// translate select options (countries) only language change
	const countryOptions = useMemo(() => {
		return residenceOptionsSelect.map((residence) => ({
			value: translateCountry(residence.value),
			code: residence.value
		}));
	}, [i18n.language]);

	const handleChangeSelectedCountry = useCallback(
		(option: SelectOption) => {
			dispatch(personaActions.setPersonaCountry(option.code));
			props.onValidFormPersonalInfo();
		},
		[dispatch, props]
	);

	const country = persona?.residence.country;
	const maxDate = moment().subtract(LimitCheckPeriod.MAX_DATE, LimitCheckPeriod.TIME_METRIC);
	return (
		<div className="personal-info-step">
			<StyledInput
				id="name"
				placeholder={firstnameTitle}
				onChange={handleChangeFirstName}
				inputValue={persona?.firstName}
				disabled={!editCheckData && persona.isFirstNamePreFilled} // disabled if editing is not allowed and there is firstName info
				minCharacters={2}
			/>
			<StyledInput
				id="lastname"
				placeholder={lastnameTitle}
				onChange={handleChangeLastName}
				inputValue={persona?.lastName}
				disabled={!editCheckData && persona.isLastNamePreFilled} // disabled if editing is not allowed and there is lastname info
				minCharacters={2}
			/>
			<StyledSelect
				label={countryTitle}
				id={'select-country'}
				options={countryOptions}
				onChange={handleChangeSelectedCountry}
				initValue={country ? { value: translateCountry(country), code: country } : undefined}
				disabled={!editCheckData && persona.isCountryPreFilled} // disabled if editing is not allowed and there is country info
			/>
			<DatePicker
				maxDate={maxDate}
				placeholder={dobTitle}
				onChange={handleChangeDateOfBirth}
				id="date-picker"
				initValue={persona?.dateOfBirth ? formatDate(persona.dateOfBirth) : null}
				disabled={!editCheckData && persona.isDOBPreFilled} // disabled if editing is not allowed and there is DOB info
			/>
		</div>
	);
};

export default PersonalInfoInput;

const formatDate = (dateOfBirth: string) => {
	return new Date(DateConverter.convert(dateOfBirth, DateFormat.API_FORMAT));
};
