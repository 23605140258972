import { AppDispatch, GetState } from '../store';
import { selectCurrentStep, setActiveStep, setBankProcessFinished } from '../slices/ui.slice';
import { EnumStepsKeys } from '../../types/constants';
import { Step } from '../../types/Step';

export const bankAppCompleted = () => {
	return (dispatch: AppDispatch, getState: GetState) => {
		const state = getState();
		const currentStep = selectCurrentStep(state);

		if (!isCurrentBankStep(currentStep)) return;

		dispatch(setBankProcessFinished());
		dispatch(setActiveStep(EnumStepsKeys.WAITING_RESULTS));
	};
};

const isCurrentBankStep = (step: Step) => {
	return step.key === EnumStepsKeys.BANK_STEP;
};
