import React from 'react';
import { ReactComponent as CheckInfoIcon } from '../../../assets/images/icons/box.svg';
import { useTheme } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import { selectTitleText } from '../../../app/slices/metadata.slice';

export const CheckTitle = () => {
	const titleText = useAppSelector(selectTitleText);

	return (
		<div className="container-personal-info">
			<div className="check-info-icon-wrapper">
				<CheckIcon />
			</div>
			<div className="check-info-text">{titleText}</div>
		</div>
	);
};

const CheckIcon = () => {
	const theme = useTheme();
	const fill = theme.palette.primary.main;
	return <CheckInfoIcon fill={fill} className="check-info-icon" />;
};
