import React from 'react';
import useCustomization from '../../hooks/useCustomization';
import { CustomizationKey } from '../../app/slices/customizations.slice';
import Markdown from 'react-markdown';
import useTitleContentParser from '../hooks/useTitleContentParser';

interface PersonalConsentBoxCustomizationProps {
	customizationKey: CustomizationKey;
	onClick: () => void;
}

const PersonalConsentBoxCustomization = ({ customizationKey, onClick }: PersonalConsentBoxCustomizationProps) => {
	const customizationText = useCustomization(customizationKey);
	const parsedText = useTitleContentParser(customizationText);

	return (
		<div className="column">
			<div className="column-header">
				<h5>
					<Markdown>{parsedText.title.slice(0)}</Markdown>
				</h5>
			</div>
			<div className="column-text" onClick={onClick}>
				<p>
					{' '}
					<Markdown>{parsedText.content}</Markdown>
				</p>
			</div>
		</div>
	);
};
export default PersonalConsentBoxCustomization;
