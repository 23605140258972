import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox, Paper, useTheme } from '@mui/material';
import { useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import {
	personaActions,
	selectConsentCollectingData,
	selectConsetForwardingData
} from '../../app/slices/persona.slice';
import { ModalEnum, uiActions } from '../../app/slices/ui.slice';
import { PERSONAL_CONSENT_PAGE_PREFIX } from '../../utils/translationPagePrefix';
import { CustomizationKey } from '../../app/slices/customizations.slice';
import PersonalConsentBoxCustomization from '../customizations/PersonalConsentBoxCustomization';

const CONSENT_COLLECTION_KEY: CustomizationKey = 'CONSENT_COLLECTION_AND_PROCESSING_SHORT';
const CONSENT_FORWARDING_KEY: CustomizationKey = 'CONSENT_FORWARDING_AND_RESULTS_SHORT';

const PersonalConsent = (props: any) => {
	const isCollectingChecked = useAppSelector(selectConsentCollectingData);
	const isForwardingChecked = useAppSelector(selectConsetForwardingData);

	const [collectingChecked, setCollectingChecked] = useState<boolean>(isCollectingChecked);
	const [forwardingChecked, setForwardingChecked] = useState<boolean>(isForwardingChecked);

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const handleChangeCollectingDataConsent = (event: any) => {
		setCollectingChecked(event.target.checked);
		dispatch(personaActions.setPersonaCollectingDataConsent(event.target.checked));
		props.onValidFormPersonalConsent();
	};

	const handleChangeForwardingDataConsent = (event: any) => {
		setForwardingChecked(event.target.checked);
		dispatch(personaActions.setPersonaForwardingDataConsent(event.target.checked));
		props.onValidFormPersonalConsent();
	};

	const handlerOpenConsentCollectingDataModal = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		dispatch(uiActions.setCollectingDataConsent(true));
		dispatch(uiActions.setCollectingForwardDataConsent(false));
		dispatch(uiActions.toggleModal(ModalEnum.CONSENT_MODAL));
	};
	const handlerOpenConsentForwardingDataModal = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		dispatch(uiActions.setCollectingForwardDataConsent(true));
		dispatch(uiActions.setCollectingDataConsent(false));
		dispatch(uiActions.toggleModal(ModalEnum.CONSENT_MODAL));
	};

	const onCollectionClick = () => {
		dispatch(personaActions.setPersonaCollectingDataConsent(!collectingChecked));
		setCollectingChecked((prev) => !prev);
		props.onValidFormPersonalConsent();
	};

	const onForwardingClick = () => {
		dispatch(personaActions.setPersonaForwardingDataConsent(!forwardingChecked));
		setForwardingChecked((prev) => !prev);
		props.onValidFormPersonalConsent();
	};

	return (
		<div className="personal-consent">
			<div className="title">
				<h3>{t(`${PERSONAL_CONSENT_PAGE_PREFIX}.consents`)}</h3>
			</div>
			<PersonaConsentBox
				onClick={onCollectionClick}
				onButtonClick={handlerOpenConsentCollectingDataModal}
				onCheckboxChange={handleChangeCollectingDataConsent}
				customizationKey={CONSENT_COLLECTION_KEY}
				header={t(`${PERSONAL_CONSENT_PAGE_PREFIX}.collection & processing of you data`)}
				checked={collectingChecked}
				id="collecting"
			/>
			<PersonaConsentBox
				onClick={onForwardingClick}
				onButtonClick={handlerOpenConsentForwardingDataModal}
				onCheckboxChange={handleChangeForwardingDataConsent}
				customizationKey={CONSENT_FORWARDING_KEY}
				header={t(`${PERSONAL_CONSENT_PAGE_PREFIX}.forwarding of your data & results`)}
				checked={forwardingChecked}
				id="forwarding"
			/>
		</div>
	);
};

export default PersonalConsent;

type PersonaConsentBoxProps = {
	onClick: () => void;
	onButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
	onCheckboxChange: (event: React.ChangeEvent) => void;
	checked: boolean;
	header: string;
	customizationKey: CustomizationKey;
	id: string;
};

const PersonaConsentBox = ({
	onClick,
	onButtonClick,
	checked,
	onCheckboxChange,
	customizationKey,
	id
}: PersonaConsentBoxProps) => {
	const theme = useTheme();

	const paperSX = {
		padding: '5px',
		borderRadius: '5px',
		marginBottom: '10px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: '1px solid #969696',
		'&:hover': {
			border: `1px solid ${theme.palette.primary.main}`,
			cursor: 'pointer'
		}
	};
	return (
		<Paper elevation={0} style={{ backgroundColor: 'transparent' }} sx={paperSX}>
			<div className="collecting-data-card">
				<div className="row">
					<PersonalConsentBoxCustomization customizationKey={customizationKey} onClick={onClick} />
					<div className="column-checkbox">
						<div className="column-checkbox-button">
							<button onClick={onButtonClick}>Details</button>
						</div>
						<div className="column-checkbox-input" onClick={onClick}>
							<Checkbox
								checked={checked}
								onChange={onCheckboxChange}
								id={id}
								sx={{
									'& .MuiSvgIcon-root': { fontSize: 30 },
									border: '0'
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</Paper>
	);
};
