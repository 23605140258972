import { Persona } from '../../check/types';
import { AppDispatch } from '../store';
import { checkReportAPI } from '../../services/CheckReportService';
import qs from 'qs';
import { handleError } from '../slices/errors.slice';
import DateConverter, { DateFormat } from '../../utils/DateConverter';
import { UpdateReportError } from '../../check/errors';
import { initializeState } from './init-state';
import { initializeProcessingStep } from './init-current-step';

export type PersonaInformation = {
	firstName: string;
	lastName: string;
	country: string;
	dateOfBirth: string;
};

export type PersonaInformationPayload = PersonaInformation & {
	acceptTermsAndConditions: 1;
	acceptPrivacyPolicy: 1;
	status: 'completed';
};

export const putPersonaData = (persona: Persona) => {
	const personaInformation = scrapePersonaInformation(persona);
	const payload = createPersonaPayload(personaInformation);

	return async (dispatch: AppDispatch) => {
		try {
			const response = await checkReportAPI.putPersonaData(payload);
			const { errors } = response.data;

			if (errors) {
				dispatch(processUpdateErrors(errors));
				return;
			}

			dispatch(initializeProcessingStep());
		} catch (error: any) {
			dispatch(handleError(error));
		}
	};
};

function scrapePersonaInformation(persona: Persona): PersonaInformation {
	const {
		firstName,
		lastName,
		dateOfBirth,
		residence: { country }
	} = persona;

	if (!firstName || !lastName || !dateOfBirth || !country) {
		throw new Error('Missing persona information');
	}

	const formattedDob = DateConverter.convert(dateOfBirth, DateFormat.API_FORMAT);
	return {
		firstName,
		lastName,
		country,
		dateOfBirth: formattedDob
	};
}

function createPersonaPayload(personaInformation: PersonaInformation) {
	const payload: PersonaInformationPayload = {
		...personaInformation,
		acceptTermsAndConditions: 1,
		acceptPrivacyPolicy: 1,
		status: 'completed'
	};

	return qs.stringify(payload);
}

const ALREADY_PROCESSED_ERROR = 'Check is being processed, editing user data at this stage is not allowed';

function processUpdateErrors(errors: Array<string>) {
	return (dispatch: AppDispatch) => {
		const error = errors[0];

		if (error === ALREADY_PROCESSED_ERROR) {
			dispatch(initializeState());
			return;
		}

		dispatch(handleError(new UpdateReportError(errors)));
	};
}
