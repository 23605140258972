import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uiActions } from './ui.slice';
import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';
import { EnumErrorType } from '../../types/ErrorsObject';
import { AppDispatch, RootState } from '../store';
import { EnumStepsKeys } from '../../types/constants';
import NewRelicAgent from '../../newrelic';
import { HttpStatus } from '../../constants/http';

interface ErrorInformation {
	type: EnumErrorType;
	error: object;
	message: string;
}

type State = ErrorInformation | {};

const initialState = {};

const errorsSlice = createSlice<State, SliceCaseReducers<State>>({
	name: 'errorsSlice',
	initialState,
	reducers: {
		setError(state, action: PayloadAction<ErrorActionPayload>) {
			const { type, error } = action.payload;
			const errorMessage = error.message;
			return {
				type,
				error,
				message: errorMessage
			};
		}
	}
});

type ErrorActionPayload = { type: EnumErrorType; error: Error };

export const errorsActions = errorsSlice.actions;

export default errorsSlice;

export const selectError = (state: RootState) => state.error;
export const selectErrorType = (state: RootState) => {
	if (!('type' in state.error)) return;
	return state.error.type;
};

export const handleError = (error: any, type?: EnumErrorType) => {
	console.log(error);
	return (dispatch: AppDispatch) => {
		NewRelicAgent.noticeError(error, { message: error.message });

		if (isGatewayResponseError(error)) {
			dispatch(
				errorsActions.setError({
					type: EnumErrorType.LOAD_ISSUES_ERROR,
					error
				})
			);
		} else {
			dispatch(
				errorsActions.setError({
					type: type || EnumErrorType.APP_ERROR,
					error
				})
			);
		}

		dispatch(uiActions.setActiveStep(EnumStepsKeys.ERROR_STEP));
	};
};

export const isGatewayResponseError = (error: any): boolean => {
	const status = error.status;
	return (
		status === HttpStatus.BAD_GATEWAY ||
		status === HttpStatus.SERVICE_UNAVAILABLE ||
		status === HttpStatus.GATEWAY_TIMEOUT
	);
};
