import React from 'react';
import Header from './Header';
import Content from './Content';
import { CustomizationKey } from '../../../app/slices/customizations.slice';

export interface FinalScreenProps {
	titleCustomizationKey: CustomizationKey;
	textCustomizationKeys: Array<CustomizationKey>;
	icon: JSX.Element;
}

const FinalScreen = ({ titleCustomizationKey, icon, textCustomizationKeys }: FinalScreenProps) => {
	return (
		<div className="final-step">
			<div className="final-step-award">{icon}</div>
			<Header customizationKey={titleCustomizationKey} />
			<br />
			<Content contentCustomizationKeys={textCustomizationKeys} />
			<br />
		</div>
	);
};

export default FinalScreen;
