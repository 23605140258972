import React, { useEffect } from 'react';
import { ICheckItem } from '../../types/Reports';
import { useAppDispatch } from '../../app/hooks';
import KontoCheckList from './KontoCheckList';
import HouseholdList from './HouseholdList';
import IDCheckComponent from './IDCheckComponent';
import { ScoreComponentWrapper } from './ScoreComponentWrapper';
import {
	CheckItemType,
	handlerOpenModalReportComments,
	handlerOpenModalReportCommentsWithValue,
	ModalEnum,
	uiActions
} from '../../app/slices/ui.slice';

interface PersonalCheckInfoProps {
	onEnableNextButton(): void;
}

const PersonalCheckInfo = ({ onEnableNextButton }: PersonalCheckInfoProps) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		onEnableNextButton();
	}, []);

	const _handlerOpenModalReportCommentsWithValue = (
		title: string,
		code: string,
		comment?: string,
		newResult?: string
	) => {
		dispatch(handlerOpenModalReportCommentsWithValue(title, code, comment, newResult));
	};

	const _handlerOpenModalReportComments = (
		title: string,
		code: string,
		comment?: string,
		isIDCheck: boolean = false
	) => {
		dispatch(handlerOpenModalReportComments(title, code, comment, isIDCheck));
	};

	const handleOpenCodeInfoModal = (checkItem: ICheckItem, type: CheckItemType) => {
		dispatch(
			uiActions.setSelectedCheckItem({
				item: checkItem,
				type
			})
		);
		dispatch(uiActions.toggleModal(ModalEnum.CODE_INFO_MODAL));
	};

	return (
		<div className="personal-check-info-step">
			<ScoreComponentWrapper />
			<IDCheckComponent
				onCommentClick={() => _handlerOpenModalReportComments('ID Check', 'NAMECHECK', '', true)}
				onInfoClick={handleOpenCodeInfoModal}
			/>
			<KontoCheckList onCommentClick={_handlerOpenModalReportComments} onInfoClick={handleOpenCodeInfoModal} />
			<HouseholdList
				onCommentClick={_handlerOpenModalReportCommentsWithValue}
				onInfoClick={handleOpenCodeInfoModal}
			/>
		</div>
	);
};

export default PersonalCheckInfo;
