import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as CheckInfoIcon } from '../../assets/images/icons/box.svg';
import { ReactComponent as LogoutIcon } from '../../assets/images/icons/log-out.svg';
import { ReactComponent as UserIcon } from '../../assets/images/icons/user.svg';
import { ReactComponent as FileTextIcon } from '../../assets/images/icons/file-text.svg';
import { EnumSteps } from '../../types/constants';
import pen from '../../assets/images/icons/pen.svg';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ErrorObject, { EnumErrorType } from '../../types/ErrorsObject';
import { EnumResultsPageType } from '../../types/Reports';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { PERSONAL_INFO_PAGE_PREFIX, RESULTS_PAGE_PREFIX } from '../../utils/translationPagePrefix';
import { selectTitleText } from '../../app/slices/metadata.slice';
import DateConverter, { DateFormat } from '../../utils/DateConverter';
import { selectError } from '../../app/slices/errors.slice';
import { goBackStep } from '../../app/slices/ui.slice';
import { selectBankAccount } from '../../app/slices/bank.slice';
import { selectPersona } from '../../app/slices/persona.slice';
import TextCustomization from '../customizations/TextCustomization';
import { CustomizationKey } from '../../app/slices/customizations.slice';

const RESULTS_PAGE_TITLE_KEY: CustomizationKey = 'RESULTS_PAGE_TITLE';

export const ContentHeader = () => {
	const activeStep = useSelector((state: any) => state.ui.activeStep);
	const persona = useAppSelector(selectPersona);
	const errorsObject = useAppSelector(selectError) as ErrorObject;
	const titleText = useAppSelector(selectTitleText);
	const bankAccount = useAppSelector(selectBankAccount);
	const pageType = useAppSelector((state) => state.ui.checkResultViewType);
	const [isOpenBankAccount, setIsOpenBankAccount] = useState(false);
	const theme = useTheme();
	const dispatch = useAppDispatch();

	const { t } = useTranslation();

	const toggleOpen = () => {
		const toggle = !isOpenBankAccount;
		setIsOpenBankAccount(toggle);
	};

	const style = {
		mainColor: theme.palette.primary.main
	};

	if (activeStep.id === EnumSteps.BankInterfaceStep) return <></>;
	if (activeStep.id === EnumSteps.NoEmailStep) return <></>;
	return (
		<div className="content-header">
			{(activeStep.id === EnumSteps.PersonalInfoStep || activeStep.id === EnumSteps.PersonalConsentStep) && (
				<div className="container-personal-info">
					<div className="check-info-icon-wrapper">
						<CheckInfoIcon fill={theme.palette.primary.main} className="check-info-icon" />
					</div>
					<div className="check-info-text">{titleText}</div>
				</div>
			)}

			{activeStep.id === EnumSteps.PersonalInfoStep && (
				<div className="container-personal-info">
					<div className="user-icon-wrapper">
						<UserIcon className="user-icon" fill={style.mainColor} />
					</div>
					<div className="user-info-container">
						<div className="user-info-text">
							{t(`${PERSONAL_INFO_PAGE_PREFIX}.header.personal information`)}
						</div>
						<div className="user-info-text-complete">
							{t(`${PERSONAL_INFO_PAGE_PREFIX}.header.please provide the following information`)}:
						</div>
					</div>
				</div>
			)}

			{activeStep.id === EnumSteps.PersonalCheckInfo && pageType === EnumResultsPageType.RESULTS_VIEW && (
				<div className="container-personal-info">
					<div className="user-icon-wrapper">
						<LogoutIcon className="user-icon" fill={style.mainColor} />
					</div>
					<div className="single-content-info">
						<div className="user-info-text" style={{ padding: 0 }}>
							<TextCustomization customizationKey={RESULTS_PAGE_TITLE_KEY} />
						</div>
					</div>
				</div>
			)}

			{(activeStep.id === EnumSteps.PersonalConsentStep ||
				(activeStep.id === EnumSteps.PersonalCheckInfo && pageType === EnumResultsPageType.RESULTS_VIEW)) && (
				<div className="container-personal-info">
					<div className="user-icon-wrapper">
						<UserIcon className="user-icon" fill={style.mainColor} />
					</div>
					<div className="user-info-container">
						<div className="user-info-text">
							{persona.firstName} {persona.lastName}
						</div>
						{persona && persona.dateOfBirth && persona.residence.country && (
							<div className="user-info-text-complete">
								{DateConverter.convert(persona.dateOfBirth, DateFormat.VIEW_FORMAT)},{' '}
								{persona &&
									persona.residence &&
									persona.residence.country &&
									capitalizeFirstLetter(t(`countries.${persona.residence.country}`))}
							</div>
						)}
					</div>
					{activeStep.id === EnumSteps.PersonalConsentStep && (
						<div className="edit-data-wrapper">
							<img src={pen} alt="edit pen" className="edit-pen" onClick={() => dispatch(goBackStep())} />
						</div>
					)}
				</div>
			)}

			{activeStep.id === EnumSteps.PersonalCheckInfo && pageType === EnumResultsPageType.RESULTS_VIEW && (
				<React.Fragment>
					<div className="container-personal-info">
						<div className="user-icon-wrapper">
							<FileTextIcon className="user-icon" fill={style.mainColor} />
						</div>
						<div className="single-content-info">
							<div className="user-info-text" style={{ padding: 0 }}>
								{t(`${RESULTS_PAGE_PREFIX}.header.bank account details`)}
							</div>
							<div className="user-info-details">
								<button onClick={toggleOpen}>Details</button>
							</div>
						</div>
					</div>
					{isOpenBankAccount && (
						<div className="container-personal-info translated-personal-info">
							<div className="user-icon-wrapper">
								<FileTextIcon className="user-icon" fill={style.mainColor} style={{ opacity: '0' }} />
							</div>
							<div className="bank-info-container">
								<div>{t(`${RESULTS_PAGE_PREFIX}.header.account owner`)}:</div>
								<div style={{ marginTop: '2px' }}>IBAN:</div>
							</div>
							<div className="bank-info-content">
								<div>{bankAccount.accountHolder || <p>{''}</p>}</div>
								<div>{bankAccount.iban || <p>{''}</p>}</div>
							</div>
						</div>
					)}
				</React.Fragment>
			)}

			{activeStep.id === EnumSteps.BankInterfaceStep ||
				(activeStep.id === EnumSteps.ErrorStep && errorsObject.type === EnumErrorType.KLARNA_ERROR && (
					<div className="container-personal-info">
						<div className="user-icon-wrapper">
							<LogoutIcon className="user-icon" fill={style.mainColor} />
						</div>
						<div className="user-info-container">
							<div className="user-info-text">Wir verbinden Sie mit Ihrer Bank</div>
							<div className="user-info-text-complete">
								Bitte loggen Sie sich einmalig bei Ihrem Gehaltskonto ein.
							</div>
						</div>
					</div>
				))}
		</div>
	);
};

export default ContentHeader;

const capitalizeFirstLetter = (str: string) => {
	if (!str) return '';
	return str.charAt(0).toUpperCase() + str.slice(1);
};
