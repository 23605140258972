import React from 'react';

export const FontPreload = () => {
	return (
		<div style={preloadStyle}>
			<span style={spanStyle1}></span>
			<span style={spanStyle2}></span>
			<span style={spanStyle3}></span>
			<span style={spanStyle4}></span>
			<span style={spanStyle5}></span>
			<span style={spanStyle6}></span>
			<span style={spanStyle7}></span>
		</div>
	);
};

const preloadStyle = {
	role: 'none presentation',
	userSelect: 'none',
	pointerEvents: 'none',
	position: 'absolute',
	top: 0,
	transform: 'translateY(-100%)',
	opacity: 0
} as any;

const spanStyle1 = {
	fontFamily: 'Roboto, sans-serif',
	fontWeight: '300'
};

const spanStyle2 = {
	fontFamily: 'Roboto, sans-serif',
	fontWeight: '400'
};

const spanStyle3 = {
	fontFamily: 'Roboto, sans-serif',
	fontWeight: '500'
};

const spanStyle4 = {
	fontFamily: 'Roboto, sans-serif',
	fontWeight: '600'
};

const spanStyle5 = {
	fontFamily: 'Roboto, sans-serif',
	fontWeight: '700'
};

const spanStyle6 = {
	fontFamily: 'Roboto, sans-serif',
	fontWeight: '200'
};

const spanStyle7 = {
	fontFamily: 'Roboto, sans-serif',
	fontWeight: '100'
};
