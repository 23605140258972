import React, { useState } from 'react';
import DE_AT_FLAG from '../../assets/images/flag_au_de.svg';
import EN_US_FLAG from '../../assets/images/English_language.svg';
import { useTranslation } from 'react-i18next';
import LanguageMenu from './LanguageMenu';
import LanguageSelectContainer from './LanguageSelectContainer';
import { Language } from '../../app/slices/customizations.slice';

export interface LanguageOption {
	label: string;
	lang: Language;
	src: string;
}

const languageOptions: Array<LanguageOption> = [
	{ label: 'English', lang: 'en', src: EN_US_FLAG },
	{ label: 'German', lang: 'de', src: DE_AT_FLAG }
];

const LanguageSelect = () => {
	const {
		i18n: { changeLanguage, language }
	} = useTranslation();

	const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
	// if anchor element is != null, open is set to true
	const isLanguageSelectOpen = Boolean(anchorElement);

	const currentLanguageOption = deriveCurrentLanguageOption(language);

	if (!currentLanguageOption) return <>Not supported language</>;

	const handleOpenLanguageSelect = (event: React.MouseEvent<HTMLDivElement>) => {
		if (isLanguageSelectOpen) setAnchorElement(null);
		else setAnchorElement(event.currentTarget);
	};

	const handleCloseLanguageSelect = () => {
		setAnchorElement(null);
	};

	const handleSelectLanguage = (languageOption: LanguageOption) => {
		changeLanguage(languageOption.lang);
		handleCloseLanguageSelect();
	};

	return (
		<div id="language-wrapper-container">
			<LanguageSelectContainer
				onClick={handleOpenLanguageSelect}
				open={isLanguageSelectOpen}
				currentLanguageFlagSrc={currentLanguageOption.src}
			/>
			<LanguageMenu
				isMenuOpen={isLanguageSelectOpen}
				onMenuClose={handleCloseLanguageSelect}
				languageOptions={languageOptions}
				onLanguageClick={handleSelectLanguage}
				currentlySelectedLanguage={currentLanguageOption.lang}
				anchorElement={anchorElement}
			/>
		</div>
	);
};

export default LanguageSelect;

function deriveCurrentLanguageOption(currentI18NLanguage: string): LanguageOption | undefined {
	return languageOptions.find((languageOption) => {
		const { lang } = languageOption;
		return testLanguageEquality(currentI18NLanguage, lang);
	});
}

// check language equality ignoring dialects (example: 'en-US' is equal to 'en')
function testLanguageEquality(inputLanguageWithDialect: string, lang: Language) {
	return new RegExp(`${lang}`).test(inputLanguageWithDialect);
}
