import { AxiosResponse } from 'axios';
import { CustomizationsRecord, Language } from '../app/slices/customizations.slice';
import { BaseService } from '../api/axios';
import { HttpHeader } from '../constants/http';

export class CustomizationService {
	async fetchCustomizations(language: Language, token: string): Promise<CustomizationsRecord> {
		const response: AxiosResponse = await BaseService.get(`/customizations/${language}`, {
			headers: {
				[HttpHeader.Authorization]: token
			}
		});

		return response.data;
	}
}

export const customizationService = new CustomizationService();
