import useCustomization from '../../hooks/useCustomization';
import LegalDocsMarkdown from '../custom/LegalDocsMarkdown';
import React from 'react';
import { CustomizationKey } from '../../app/slices/customizations.slice';

const CONDITIONS_KEY: CustomizationKey = 'CONDITIONS';

const AGBModalCustomization = () => {
	const textCustomization = useCustomization(CONDITIONS_KEY);

	return <LegalDocsMarkdown content={textCustomization} />;
};

export default AGBModalCustomization;
