import React from 'react';
//@ts-ignore
//@ts-ignore
import HtmlToReact, { Parser as HtmlToReactParser } from 'html-to-react';

export type PlaceholderObject<T = {}> = {
	name: string;
	value: string | React.ReactElement | ((sourceObject: T, children: any) => React.ReactElement);
};

export type PlaceholdersRecord<T = {}> = Record<string, PlaceholderObject<T>>;

export type TemplateObject<T> = {
	template: string;
	placeholders?: PlaceholdersRecord<T>;
	sourceObject: T;
};

interface ITemplateObjectBuilder<T> {
	addPlaceholderObject(placeholderObject: PlaceholderObject<T>): ITemplateObjectBuilder<T>;

	addPlaceholderObjects(placeholderObjects: Array<PlaceholderObject<T>>): ITemplateObjectBuilder<T>;

	build(): TemplateObject<T>;
}

export class TemplateObjectBuilder<T extends object = {}> implements ITemplateObjectBuilder<T> {
	private readonly templateObject: TemplateObject<T>;

	public constructor(template: string, sourceObject?: T) {
		this.templateObject = {
			template,
			sourceObject: sourceObject || ({} as T)
		};
	}

	public addPlaceholderObject(placeholderObject: PlaceholderObject<T>): ITemplateObjectBuilder<T> {
		const { name } = placeholderObject;

		if (!this.templateObject.placeholders) this.templateObject.placeholders = {};
		this.templateObject.placeholders[name] = placeholderObject;

		return this;
	}

	public addPlaceholderObjects(placeholderObjects: Array<PlaceholderObject<T>>): ITemplateObjectBuilder<T> {
		placeholderObjects.forEach((placeholderObject) => {
			this.addPlaceholderObject(placeholderObject);
		});
		return this;
	}

	public build(): TemplateObject<T> {
		return this.templateObject;
	}
}

export interface IHTMLTemplateParser<T> {
	parseHTMLTemplate(templateObject: TemplateObject<T>): React.ReactElement;
}

// Parser configuration:
interface IHTMLTemplateParserConfig {
	placeholderAttribute?: string;
	removePlaceholders?: boolean;
}

// Configuration defaults:
const DEFAULT_PLACEHOLDER_ATTR = '_placeholder';
const DEFAULT_REMOVE_PLACEHOLDERS = true;

/**
 * Default implementation of HTML Template Parser class
 */
export class HTMLTemplateParser<T extends object = {}> implements IHTMLTemplateParser<T> {
	private readonly placeholderAttribute: string = DEFAULT_PLACEHOLDER_ATTR;
	private readonly removePlaceholders: boolean = DEFAULT_REMOVE_PLACEHOLDERS;

	public constructor(config?: IHTMLTemplateParserConfig) {
		if (config) {
			this.placeholderAttribute = config.placeholderAttribute || DEFAULT_PLACEHOLDER_ATTR;
			this.removePlaceholders =
				config.removePlaceholders !== undefined ? config.removePlaceholders : DEFAULT_REMOVE_PLACEHOLDERS;
		}
	}

	public parseHTMLTemplate<T extends object = {}>(templateObject: TemplateObject<T>) {
		const { template, placeholders } = templateObject;
		const htmlToReactParser = new HtmlToReactParser();

		// if there are placeholders, define specific processing instructions
		if (placeholders) {
			const processingInstructions = this.generateProcessingInstructions<T>(templateObject);

			// parse with defined processing instructions
			return htmlToReactParser.parseWithInstructions(template, this.isValidNode, processingInstructions);
		}

		return htmlToReactParser.parse(template);
	}

	private generateProcessingInstructions<T extends object = {}>(templateObject: TemplateObject<T>) {
		const { placeholders, sourceObject } = templateObject;
		const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
		const self = this;
		// Custom processing instructions for parser
		return [
			{
				replaceChildren: true,
				shouldProcessNode: function (node: any) {
					return node.attribs && node.attribs[self.placeholderAttribute];
				},
				processNode: function (node: any, children: any) {
					const placeholder = node.attribs[self.placeholderAttribute];
					// remove placeholder attribute (if configured)
					if (self.removePlaceholders) delete node.attribs[self.placeholderAttribute];
					// find placeholder value to be inserted
					const placeholderObject = (placeholders as PlaceholdersRecord<T>)[placeholder];
					// if there is placeholder object, replace children
					if (placeholderObject)
						return self.extractPlaceholderValue<T>(placeholderObject, sourceObject, children);
					else return children;
				}
			},
			{
				// Anything else
				shouldProcessNode: function () {
					return true;
				},
				processNode: processNodeDefinitions.processDefaultNode
			}
		];
	}

	private extractPlaceholderValue<T extends object = {}>(
		placeholderObject: PlaceholderObject<T>,
		sourceObject: T,
		children: any
	) {
		const { value } = placeholderObject;
		let placeholderValue: React.ReactElement = <></>;

		switch (typeof value) {
			case 'function':
				// if placeholder value is a function, call it to get real value
				placeholderValue = value(sourceObject, children);
				break;
			case 'string':
				// if placeholder value is string, convert it to ReactElement using Fragments
				placeholderValue = <>{value}</>;
				break;
			default:
				// default: it is ReactElement already
				placeholderValue = value;
		}

		return placeholderValue;
	}

	private isValidNode() {
		return true;
	}
}

type CompareFunction<T> = (tpo1: TemplateObject<T>, tpo2: TemplateObject<T>) => boolean;
type ParserCompare<T> = CompareFunction<T> | keyof T;
type CacheMap<T, P extends ParserCompare<T>> = Map<
	P extends CompareFunction<T> ? TemplateObject<T> : T[keyof T],
	React.ReactElement
>;

/**
 * Caching proxy for HTML Parser
 * - proxy has a reference to real concrete implementation of parser
 * - proxy caches results that are returned by parser so every subsequent call with same params will return cached result
 */
export class HTMLTemplateParserProxy<T extends object = {}, P extends ParserCompare<T> = ParserCompare<T>>
	implements IHTMLTemplateParser<T>
{
	private readonly htmlTemplateParser: HTMLTemplateParser<T>;
	private readonly compare: ParserCompare<T>;
	// cache map
	private readonly cacheMap: CacheMap<T, P> = new Map([]);

	public constructor(parser: HTMLTemplateParser<T>, compare: P) {
		this.htmlTemplateParser = parser;
		this.compare = compare;
	}

	public parseHTMLTemplate(templateObject: TemplateObject<T>): React.ReactElement {
		const cachedResult = this.searchCache(templateObject);
		// if there is cached result, just return it.
		if (cachedResult !== undefined) return cachedResult;

		const parsedResult: React.ReactElement = this.htmlTemplateParser.parseHTMLTemplate(templateObject);
		// cache new result
		this.saveResult(templateObject, parsedResult);

		return parsedResult;
	}

	private searchCache(templateObject: TemplateObject<T>): React.ReactElement | undefined {
		// if compare is function, traverse map and check manually
		if (typeof this.compare === 'function') {
			for (let [tObject, parsedResult] of this.cacheMap) {
				// if there is, return cache result
				if (this.compare(tObject as TemplateObject<T>, templateObject)) {
					return parsedResult;
				}
			}
			// return undefined if cache doesn't contain element
			return undefined;
		}

		// else compare is property on sourceObject
		const { sourceObject } = templateObject;
		const key = sourceObject[this.compare];
		return this.cacheMap.get(key as any);
	}

	private saveResult(templateObject: TemplateObject<T>, result: React.ReactElement) {
		if (typeof this.compare === 'function') {
			this.cacheMap.set(templateObject as any, result);
			return;
		}

		// else compare is property on sourceObject
		const { sourceObject } = templateObject;
		const key = sourceObject[this.compare];
		this.cacheMap.set(key as any, result);
	}
}

type ParserWithCachingConfig<T> = {
	enableCaching: true;
	compare: ParserCompare<T>;
};

type ParserWithoutCachingConfig = {
	enableCaching: false;
};

// discriminant union
type CachingParserConfig<T> = ParserWithCachingConfig<T> | ParserWithoutCachingConfig;

type ParserConfig<T> = CachingParserConfig<T> & IHTMLTemplateParserConfig;

/**
 *  HTMLTemplateParser Factory object
 *  - if cacheable value in config is true, Parser Proxy will be returned
 *  - else concrete implementation will be returned
 */
class HTMLTemplateParserFactoryClass {
	private readonly defaultConfig: ParserConfig<{}> = {
		enableCaching: false,
		placeholderAttribute: DEFAULT_PLACEHOLDER_ATTR
	};

	public createHTMLTemplateParser<T extends object = {}>(config?: ParserConfig<T>): IHTMLTemplateParser<T> {
		let _config = config || this.defaultConfig;
		let parser: IHTMLTemplateParser<T> = new HTMLTemplateParser<T>(config);

		if (_config.enableCaching) {
			// if caching is enabled return caching proxy for HTMLTemplateParser
			parser = new HTMLTemplateParserProxy<T>(parser as HTMLTemplateParser<T>, _config.compare as any);
		}

		return parser;
	}
}

export const HTMLTemplateParserFactory = new HTMLTemplateParserFactoryClass();
