import React from 'react';
import FAQsMarkdown from '../custom/FAQsMarkdown';

type AccordionCategoryProps = {
	markdown?: string;
};

export const AccordionCategory = ({ markdown }: AccordionCategoryProps) => {
	return (
		<React.Fragment>
			<FAQsMarkdown markdownText={markdown} />
		</React.Fragment>
	);
};
