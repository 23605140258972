import { axios_instance } from './axios';
import store from '../app/store';
import { selectShortTermJWT } from '../app/slices/session.slice';

const EMAIL_PATH = 'check/email';

interface ErrorResponse {
	error?: Array<{ msg: string }>;
}

export class EmailService {
	public async sendEmailAddress(email: string): Promise<void> {
		const formData = new URLSearchParams();
		formData.append('email', email);

		try {
			const response = await axios_instance.put(EMAIL_PATH, formData.toString(), {
				headers: {
					Authorization: selectShortTermJWT(store.getState())
				}
			});

			const responseData = response.data as ErrorResponse;

			if (hasInvalidValueError(responseData)) {
				throw new InvalidEMailValueError();
			}
		} catch (error) {
			if (isNotAcceptableStatus(error)) throw new CheckUpdateNotAcceptableError();
			throw error;
		}
	}
}

export const emailAPI = new EmailService();

export class InvalidEMailValueError extends Error {
	constructor() {
		super('Invalid email value');
	}
}

export class CheckUpdateNotAcceptableError extends Error {
	constructor() {
		super('Check update not acceptable');
	}
}

const hasInvalidValueError = (responseData: ErrorResponse): boolean | undefined => {
	return responseData.error && responseData.error.length > 0 && responseData.error[0].msg === 'Invalid value';
};

const isNotAcceptableStatus = (error: any): boolean => {
	return error.response && error.response.status === 406;
};
