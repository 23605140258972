import React from 'react';
import { CustomizationKey } from '../../../app/slices/customizations.slice';
import TextCustomization from '../../customizations/TextCustomization';

interface ContentProps {
	contentCustomizationKeys: Array<CustomizationKey>;
}

export const Content = ({ contentCustomizationKeys }: ContentProps) => {
	return (
		<>
			{contentCustomizationKeys.map((key: CustomizationKey) => {
				return <Text textCustomizationKey={key} />;
			})}
		</>
	);
};

export default Content;

interface TextProps {
	textCustomizationKey: CustomizationKey;
}

const Text = ({ textCustomizationKey }: TextProps) => {
	return (
		<div className="final-step-award-text" key={textCustomizationKey}>
			<TextCustomization customizationKey={textCustomizationKey} />
			<br />
			<br />
		</div>
	);
};
