import React from 'react';
import FooterNavigation from './FooterNavigation';
import FooterPowerBy from './FooterPowerBy';

const Footer = () => {
	return (
		<>
			<footer>
				<FooterNavigation />
				<FooterPowerBy />
			</footer>
		</>
	);
};

export default Footer;
