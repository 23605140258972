import React from 'react';
import { useTranslation } from 'react-i18next';

type LanguageSelectContainerProps = {
	onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
	open: boolean;
	currentLanguageFlagSrc: string;
};

const LanguageSelectContainer = React.memo(
	({ onClick, open, currentLanguageFlagSrc }: LanguageSelectContainerProps) => {
		const { t } = useTranslation();

		return (
			<div
				onClick={onClick}
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				id="language-container"
				className="language-link-2"
				style={{ display: 'flex' }}
			>
				<img className="language-flag" src={currentLanguageFlagSrc} alt="Language" />
				<span style={{ paddingLeft: '5px' }}>{t('footer.language')}</span>
			</div>
		);
	}
);

export default LanguageSelectContainer;
