import React, { MouseEventHandler } from 'react';
import DiscardResultsTextCustomization from '../customizations/DiscardResultsTextCustomization';

interface DiscardResultsTextProps {
	onDiscard(): void;
	disabled: boolean;
}

export const DiscardResultsText = ({ onDiscard, disabled }: DiscardResultsTextProps) => {
	const onDiscardClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
		event.preventDefault();
		event.stopPropagation();
		if (!disabled) onDiscard();
	};

	return (
		<div className="details-under-button">
			<DiscardResultsTextCustomization onClick={onDiscardClick} />
		</div>
	);
};

export default DiscardResultsText;
