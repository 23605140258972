import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { stepsConstant } from '../../types/constants';
import { EnumResultsPageType, ICheckItem } from '../../types/Reports';
import { RootState } from '../store';
import { Step } from '../../types/Step';

export enum ModalEnum {
	INFO_MODAL = 1,
	CONSENT_MODAL,
	REPORT_INFO_MODAL,
	REPORT_COMMENT_MODAL,
	REPORT_COMMENT_WITH_VALUE_MODAL,
	PRIVACY_MODAL,
	AGB_MODAL,
	IMPRESSIUM_MODAL,
	CODE_INFO_MODAL,
	CRITERIA_INFO_MODAL,
	XS2ATERMS_MODAL,
	XS2APRIVACY_MODAL
}

export type CompletedCheckType =
	| 'ACCEPTED'
	| 'DISCARDED'
	| 'ACCEPTED_RELOADED'
	| 'DISCARDED_RELOADED'
	| 'EMAIL_VALIDATION_FINISHED';

const ModalsMap: Map<ModalEnum, string> = new Map([
	[ModalEnum.AGB_MODAL, 'agbModalShown'],
	[ModalEnum.CONSENT_MODAL, 'consentModalIsShown'],
	[ModalEnum.IMPRESSIUM_MODAL, 'impressiumModalIsShown'],
	[ModalEnum.REPORT_COMMENT_MODAL, 'reportCommentModalIsShown'],
	[ModalEnum.PRIVACY_MODAL, 'privacyModalIsShown'],
	[ModalEnum.INFO_MODAL, 'infoModalIsShown'],
	[ModalEnum.REPORT_INFO_MODAL, 'reportInfoModalIsShown'],
	[ModalEnum.REPORT_COMMENT_WITH_VALUE_MODAL, 'reportCommentWithValueShown'],
	[ModalEnum.CODE_INFO_MODAL, 'codeInfoModalIsShown'],
	[ModalEnum.CRITERIA_INFO_MODAL, 'criteriaInfoModalIsShown'],
	[ModalEnum.XS2APRIVACY_MODAL, 'xs2aPrivacyModalIsShown'],
	[ModalEnum.XS2ATERMS_MODAL, 'xs2aTermsModalIsShown']
]);

export type CheckItemType = 'KONTO' | 'IN_OUT';

const uiSlice = createSlice({
	name: 'ui',
	initialState: {
		activeStep: stepsConstant[0],
		steps: stepsConstant,
		loading: false,
		modalTitle: '',
		reportCommentCode: '',
		reportCommentText: '',
		reportCommentValue: 0,
		reportCommentTextIDCheck: '',
		reportCommentCheckIdShown: false,
		infoModalIsShown: false,
		consentModalIsShown: false,
		reportInfoModalIsShown: false,
		reportCommentModalIsShown: false,
		reportCommentWithValueShown: false,
		collectingDataConsent: false,
		collectingForwardDataConsent: false,
		codeInfoModalIsShown: false,
		criteriaInfoModalIsShown: false,
		selectedCheckItem: {},
		selectedCheckItemType: 'KONTO',
		privacyModalIsShown: false,
		agbModalShown: false,
		impressiumModalIsShown: false,
		xs2aTermsModalIsShown: false,
		xs2aPrivacyModalIsShown: false,
		bankProcessFinished: false,
		completedCheckType: '',
		checkResultViewType: EnumResultsPageType.RESULTS_SUMMARY
	},
	reducers: {
		setStepsFlag(state, action) {
			state.steps.map((x) => {
				if (x.key === action.payload.key) x.isDone = true;
				return x;
			});
		},
		setPreviousStepsFlag(state, action) {
			state.steps.map((x) => {
				if (x.id < action.payload.id) x.isDone = true;
				return x;
			});
		},
		setActiveStep(state, action) {
			const steps = state.steps;

			const index = steps.findIndex((x: any) => x.key === action.payload);

			const currentStep = steps[index];
			state.steps.map((x) => {
				if (x.id < currentStep.id) x.isDone = true;
				return x;
			});
			state.activeStep = currentStep;
		},
		toggleModal(state, action) {
			const modalType: ModalEnum = action.payload;
			const property = ModalsMap.get(modalType);
			if (!property) return state;
			const currentValue: boolean = (state as any)[property];
			// if action is closing modal, just close modal
			if (currentValue) (state as any)[property] = false;
			else {
				ModalsMap.forEach((property, key) => {
					// open modal that is wanted
					if (key === modalType) (state as any)[property] = true;
					else {
						// close all other modals
						(state as any)[property] = false;
					}
				});
			}
		},
		setStartLoadingFlag(state) {
			state.loading = true;
		},
		setEndLoadingFlag(state) {
			state.loading = false;
		},
		setModalTitle(state, action) {
			state.modalTitle = action.payload;
		},
		setReportCommentCode(state, action) {
			state.reportCommentCode = action.payload;
		},
		setReportCommentText(state, action) {
			state.reportCommentText = action.payload;
		},
		setReportCommentValue(state, action) {
			state.reportCommentValue = action.payload;
		},
		setReportCommentTextIDCheck(state, action) {
			state.reportCommentTextIDCheck = action.payload;
		},
		setReportCommentCheckIDModal(state) {
			state.reportCommentCheckIdShown = true;
		},
		setReportCommentCheckIDModalHide(state) {
			state.reportCommentCheckIdShown = false;
		},
		setCollectingDataConsent(state, action) {
			state.collectingDataConsent = action.payload;
		},
		setCollectingForwardDataConsent(state, action) {
			state.collectingForwardDataConsent = action.payload;
		},
		setBankProcessFinished(state) {
			state.bankProcessFinished = true;
		},
		setFinalStepType(state, action) {
			state.completedCheckType = action.payload;
		},
		setSelectedCheckItem(state, action: PayloadAction<{ item: ICheckItem; type: CheckItemType }>) {
			state.selectedCheckItem = action.payload.item;
			state.selectedCheckItemType = action.payload.type;
		},
		setCheckResultsViewType(state, action) {
			state.checkResultViewType = action.payload;
		}
	}
});

export const selectCurrentStep = (state: RootState): Step => {
	return state.ui.activeStep;
};

export const selectCheckResultType = (selfAssessment: boolean): EnumResultsPageType => {
	return selfAssessment ? EnumResultsPageType.RESULTS_VIEW : EnumResultsPageType.RESULTS_SUMMARY;
};

export const selectSelectedCheckItem = (state: RootState) => {
	return state.ui.selectedCheckItem;
};

export const selectCompletedCheckType = (state: RootState) => {
	return state.ui.completedCheckType as CompletedCheckType;
};

export const uiActions = uiSlice.actions;

export const { setActiveStep, setBankProcessFinished, setFinalStepType } = uiActions;

export const goBackStep = () => {
	return (dispatch: any, getState: any) => {
		const state = getState() as RootState;
		const { id: activeStepID } = state.ui.activeStep;

		if (activeStepID === '0' || activeStepID === '1') return;

		const prevStep = stepsConstant[Number(activeStepID) - 1];
		dispatch(uiActions.setStepsFlag(prevStep));
		dispatch(uiActions.setActiveStep(prevStep.key));
	};
};

export const handlerOpenModalReportCommentsWithValue = (
	title: string,
	code: string,
	comment?: string,
	newResult?: string
) => {
	return (dispatch: any) => {
		dispatch(uiActions.setModalTitle(code));
		dispatch(uiActions.setReportCommentCode(code));

		if (comment) dispatch(uiActions.setReportCommentText(comment));
		else dispatch(uiActions.setReportCommentText(''));

		if (newResult) dispatch(uiActions.setReportCommentValue(newResult));
		else dispatch(uiActions.setReportCommentValue(''));

		dispatch(uiActions.toggleModal(ModalEnum.REPORT_COMMENT_WITH_VALUE_MODAL));
	};
};

export const handlerOpenModalReportComments = (
	title: string,
	code: string,
	comment?: string,
	isIDCheck: boolean = false
) => {
	return (dispatch: any, getState: any) => {
		const reportCommentTextIDCheck = getState().ui.reportCommentTextIDCheck;

		dispatch(uiActions.setModalTitle(code));
		dispatch(uiActions.setReportCommentCode(code));

		if (comment) dispatch(uiActions.setReportCommentText(comment));
		else dispatch(uiActions.setReportCommentText(''));

		dispatch(uiActions.toggleModal(ModalEnum.REPORT_COMMENT_MODAL));

		if (isIDCheck) {
			dispatch(uiActions.setReportCommentCheckIDModal());
			dispatch(uiActions.setReportCommentTextIDCheck(reportCommentTextIDCheck));
		}
	};
};

export default uiSlice;
