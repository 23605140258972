import { SVGIcon } from './SVGIcon';
import React from 'react';

export function CloudLightning() {
	return (
		<SVGIcon
			width="296.331"
			height="296.153"
			viewBox="0 0 296.331 296.153"
			size={{ x1: '1', y1: '0.47', x2: '0', y2: '0.475' }}
		>
			<g id="cloud-lightning" transform="translate(-0.987)">
				<path
					id="Pfad_116"
					data-name="Pfad 116"
					d="M168.381,125.449a12.338,12.338,0,0,1,4.145,15.866l-.728,1.234L135.23,197.4h50.979a12.338,12.338,0,0,1,10.968,17.988l-.7,1.234-49.35,74.026A12.338,12.338,0,0,1,125.865,278.2l.728-1.234,36.544-54.89H112.183a12.338,12.338,0,0,1-10.968-17.988l.7-1.234,49.35-74.026a12.338,12.338,0,0,1,17.112-3.38ZM112.109,0a111.039,111.039,0,0,1,103.34,70.412l1.344,3.614h6.428a74.026,74.026,0,0,1,73.384,63.761l.333,2.825A74.026,74.026,0,0,1,238.026,220.6a12.34,12.34,0,1,1-4.935-24.182,49.35,49.35,0,0,0-7.4-97.714H207.676a12.338,12.338,0,0,1-11.943-9.253,86.363,86.363,0,1,0-125.5,97.159A12.34,12.34,0,0,1,58.28,208.2,111.038,111.038,0,0,1,112.109,0Z"
					transform="translate(0 0)"
					fill="url(#linear-gradient)"
				/>
			</g>
		</SVGIcon>
	);
}
