import React, { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import StyledInput, { ValidationResult } from '../../custom/StyledInput';
import StepButton from '../StepButton';
import { personaActions, selectPersonaEmail } from '../../../app/slices/persona.slice';
import { EnumStepsKeys } from '../../../types/constants';
import { uiActions } from '../../../app/slices/ui.slice';
import { emailAPI } from '../../../services/EmailService';
import TextCustomization from '../../customizations/TextCustomization';
import { CustomizationKey } from '../../../app/slices/customizations.slice';
import { Header } from './Header';
import { BlankCheckEmailInputConsentCustomization } from '../../customizations/BlankCheckEmailInputCustomizations';
import { useTranslation } from 'react-i18next';
import { EMAIL_PAGE_PREFIX } from '../../../utils/translationPagePrefix';
import { EnumErrorType } from '../../../types/ErrorsObject';
import { errorsActions, isGatewayResponseError } from '../../../app/slices/errors.slice';

const BLANK_CHECK_EMAIL_INPUT_PAGE_TITLE_KEY: CustomizationKey = 'BLANK_CHECK_EMAIL_INPUT_PAGE_TITLE';
const BLANK_CHECK_EMAIL_INPUT_PAGE_SUBTITLE_KEY: CustomizationKey = 'BLANK_CHECK_EMAIL_INPUT_PAGE_SUBTITLE';

const BlankCheckInputStep = () => {
	const dispatch = useAppDispatch();

	const { t } = useTranslation();

	const emailInputPlaceholder = t(`${EMAIL_PAGE_PREFIX}.email-address-placeholder`);
	const continueButtonText = t(`${EMAIL_PAGE_PREFIX}.continue-button-text`);

	const email = useAppSelector(selectPersonaEmail);

	const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

	const handleChangeEmail = useCallback(
		(result: ValidationResult) => {
			dispatch(personaActions.setPersonaEmail(result.value));
			setIsNextButtonDisabled(!result.isValid);
		},
		[dispatch]
	);

	const changeStatus = async (): Promise<void> => {
		try {
			await emailAPI.sendEmailAddress(email);
			dispatch(uiActions.setActiveStep(EnumStepsKeys.EMAIL_SENT_STEP));
		} catch (error) {
			if (isGatewayResponseError(error)) {
				dispatch(
					errorsActions.setError({
						type: EnumErrorType.LOAD_ISSUES_ERROR,
						error
					})
				);
			} else {
				dispatch(
					errorsActions.setError({
						type: EnumErrorType.APP_ERROR,
						error
					})
				);
			}
			dispatch(uiActions.setActiveStep(EnumStepsKeys.ERROR_STEP));
		}
	};

	return (
		<React.Fragment>
			<Header />
			<div className="email-step">
				<br />
				<br />
				<div className="email-step-title-style">
					<TextCustomization customizationKey={BLANK_CHECK_EMAIL_INPUT_PAGE_TITLE_KEY} />
				</div>
				<br />
				<div className="email-step-sub-title-style">
					{' '}
					<TextCustomization customizationKey={BLANK_CHECK_EMAIL_INPUT_PAGE_SUBTITLE_KEY} />
				</div>
				<StyledInput
					id="email"
					placeholder={emailInputPlaceholder}
					inputValue={email}
					onChange={handleChangeEmail}
					validateEmail={true}
					isTextOnly={false}
					maxCharacters={45}
				/>
				<div>
					<br />
					<div className="final-step-award-text">
						<BlankCheckEmailInputConsentCustomization />
						<br />
						<br />
					</div>
				</div>
				<StepButton
					disabled={isNextButtonDisabled}
					className={`${isNextButtonDisabled && 'disabled-next-button'}`}
					onClick={() => changeStatus()}
					title={continueButtonText}
				/>
			</div>
		</React.Fragment>
	);
};

export default BlankCheckInputStep;
