import React from 'react';
import Markdown from 'react-markdown';

interface EmailReplacementMarkdownProps {
	content: string;
	email: string;
}

interface ReturnToEmailInputMarkdownProps {
	content: string;
	onClick: () => void;
}

export const EmailReplacementMarkdown: React.FC<EmailReplacementMarkdownProps> = ({ content, email }) => {
	const customComponents = {
		code: ({ inline, node, ...props }: any) => {
			if (!inline && props.children) {
				const codeBlockStyle = {
					fontWeight: '550',
					fontFamily: 'Roboto, sans-serif'
				};
				return (
					<code style={codeBlockStyle} {...props}>
						{email}
					</code>
				);
			}
			return <code {...props} />;
		}
	};

	return (
		<Markdown className="custom-markdown" components={customComponents}>
			{content}
		</Markdown>
	);
};

export const ReturnToEmailInputMarkdown: React.FC<ReturnToEmailInputMarkdownProps> = ({ onClick, content }) => {
	const customComponents = {
		code: ({ inline, node, ...props }: any) => {
			const codeBlockStyle = {
				textDecoration: 'underline',
				cursor: 'pointer',
				fontFamily: 'Roboto, sans-serif'
			};
			return <code style={codeBlockStyle} {...props} onClick={onClick} />;
		}
	};

	return (
		<Markdown className="custom-markdown" components={customComponents}>
			{content}
		</Markdown>
	);
};
