import React from 'react';
import Header from '../header/Header';
import ApplicationWrapper from '../boundaries/ApplicationWrapper';
import Footer from '../footer/Footer';
import ModalAggregate from '../modals/ModalAggregate';
import logoImg from '../../assets/images/logoBck.svg';
import { InitializationBoundary } from '../boundaries/InitializationBoundary';
import LanguageSetter from '../boundaries/LanguageSetter';

export const MainLayout = () => {
	return (
		<div className="App">
			<div className="App-center" style={backgroundStyle}>
				<InitializationBoundary>
					<LanguageSetter>
						<div className="App-wrapper">
							<Header />
							<main>
								<ApplicationWrapper />
							</main>
						</div>
					</LanguageSetter>
					<Footer />
				</InitializationBoundary>
			</div>
			<ModalAggregate />
		</div>
	);
};

const backgroundStyle = {
	backgroundImage: `url(${logoImg})`,
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	color: '#FAFAFA',
	backgroundSize: '1300px 900px',
	backgroundPositionX: '-550px',
	backgroundPositionY: '-50px'
};
