import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import StepButton from '../StepButton';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import GradientCheckIcon from '../../svg/GradientCheckIcon';
import { Persona } from '../../../check/types';
import { EnumStepsKeys } from '../../../types/constants';
import { uiActions } from '../../../app/slices/ui.slice';
import { selectPersona } from '../../../app/slices/persona.slice';
import { selectLongTermJWT } from '../../../app/slices/session.slice';
import TextCustomization from '../../customizations/TextCustomization';
import { CustomizationKey } from '../../../app/slices/customizations.slice';

const BLANK_CHECK_EMAIL_VERIFIED_PAGE_TITLE_KEY: CustomizationKey = 'BLANK_CHECK_EMAIL_VERIFIED_PAGE_TITLE';
const BLANK_CHECK_EMAIL_VERIFIED_PAGE_SUBTITLE_KEY: CustomizationKey = 'BLANK_CHECK_EMAIL_VERIFIED_PAGE_SUBTITLE';

const EmailVerifiedStep = () => {
	const dispatch = useAppDispatch();
	const persona = useAppSelector(selectPersona);
	const longTermToken = useAppSelector(selectLongTermJWT);
	const theme = useTheme();
	const [isNextButtonDisabled] = useState(true);

	const style = {
		mainColor: theme.palette.primary.main,
		secondaryColor: theme.palette.secondary.main
	};

	const handleContinue = () => {
		const isPrefilled = isPersonaPrefilled(persona as unknown as Persona);
		const step = isPrefilled ? EnumStepsKeys.SECOND_STEP : EnumStepsKeys.FIRST_STEP;
		window.history.pushState({}, '', `/${longTermToken}`);
		dispatch(uiActions.setActiveStep(step));
	};

	useEffect(() => {
		setTimeout(() => {
			const isPrefilled = isPersonaPrefilled(persona as unknown as Persona);
			const step = isPrefilled ? EnumStepsKeys.SECOND_STEP : EnumStepsKeys.FIRST_STEP;
			window.history.pushState({}, '', `/${longTermToken}`);
			dispatch(uiActions.setActiveStep(step));
		}, 180000);
	}, []);

	return (
		<div className="email-step" style={{ marginTop: '120px' }}>
			<div className="email-step-container">
				<div style={{ marginBottom: '10px' }}>
					<GradientCheckIcon />
				</div>
				<br />
				<div className="email-step-main-title-style" style={{ color: `${style.mainColor}` }}>
					<TextCustomization customizationKey={BLANK_CHECK_EMAIL_VERIFIED_PAGE_TITLE_KEY} />
				</div>
				<br />
				<div className="email-step-sub-title-style">
					<TextCustomization customizationKey={BLANK_CHECK_EMAIL_VERIFIED_PAGE_SUBTITLE_KEY} />
				</div>
				<br />
			</div>
			<StepButton
				disabled={false}
				className={`${isNextButtonDisabled && 'disabled-next-button'}`}
				onClick={handleContinue}
				title="Continue"
			/>
		</div>
	);
};

export default EmailVerifiedStep;

const isPersonaPrefilled = (persona: Persona) => {
	return (
		persona.firstName && persona.lastName && persona.residence && persona.residence.country && persona.dateOfBirth
	);
};
