export const enum HttpHeader {
	'Authorization' = 'Authorization',
	'x-check-token' = 'x-check-token',
	'Content-Type' = 'Content-Type',
	'Check-Origin' = 'Check-Origin'
}

export const enum HttpStatus {
	OK = 200,
	CREATED = 201,
	NO_CONTENT = 204,
	RESET_CONTENT = 205,
	ACCESS_FORBIDDEN = 401,
	NOT_ACCEPTABLE = 406,
	BAD_GATEWAY = 502,
	SERVICE_UNAVAILABLE = 503,
	GATEWAY_TIMEOUT = 504
}

export const enum ContentType {
	'application/x-www-form-urlencoded' = 'application/x-www-form-urlencoded'
}
