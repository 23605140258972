import useCustomization from '../../hooks/useCustomization';
import LegalDocsMarkdown from '../custom/LegalDocsMarkdown';
import React from 'react';
import { CustomizationKey } from '../../app/slices/customizations.slice';

const PRIVACY_KEY: CustomizationKey = 'PRIVACY';

const PrivacyModalCustomization = () => {
	const textCustomization = useCustomization(PRIVACY_KEY);

	return <LegalDocsMarkdown content={textCustomization} />;
};

export default PrivacyModalCustomization;
