import React from 'react';
import './App.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { AggregateAppProvider } from './components/providers/AggregateAppProvider';
import { MainLayout } from './components/layout/MainLayout';
import { FontPreload } from './components/layout/FontPreload';

function App() {
	return (
		<Router>
			<AggregateAppProvider>
				<FontPreload />
				<MainLayout />
			</AggregateAppProvider>
		</Router>
	);
}

export default App;
