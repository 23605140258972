import {
	ALIMONY,
	ENTERTAINMENT_COMMUNICATION,
	INCOME,
	INFLOW,
	KONTOCHECK,
	NAMECHECK,
	OUTFLOW,
	WELFARE
} from '../../check/codes';
import { parseResultForValue } from '../../utils/report-utils';
import { FlagsEnum, selectFlag, selectShowResults } from '../slices/flags.slice';
import { EnumSteps, EnumStepsKeys, stepsConstant } from '../../types/constants';
import { uiActions } from '../slices/ui.slice';
import { EnumResultsPageType, ICheckItem } from '../../types/Reports';
import { reportsActions } from '../slices/report.slice';
import { handleError } from '../slices/errors.slice';
import { selectPersona } from '../slices/persona.slice';
import { RootState } from '../store';
import { completeCheck, CompleteStatus } from './complete-check';
import { putPersonaData } from './put-persona-data';

const groupNamesWhichDontShow = ['INCOME', 'WELFARE', 'ALIMONY', 'ENTERTAINMENT_COMMUNICATION'];

// TODO: Needs to be refactored ASAP
export const parseKontoData = (fincredibleReport: any) => {
	return (dispatch: any) => {
		const kontoCheckArray: any = [];
		const householdArray: any = [];

		if (fincredibleReport.report?.length > 0) {
			fincredibleReport.report.forEach((elm: ICheckItem) => {
				if (elm.hasOwnProperty('code')) {
					if (KONTOCHECK[elm.code as keyof typeof KONTOCHECK]) {
						kontoCheckArray.push(elm);
					}

					if (INFLOW[elm.code as keyof typeof INFLOW]) {
						if (!groupNamesWhichDontShow.includes(elm.code)) {
							addItemValueToHouseholdArray(elm, householdArray, false);
						}
					}

					if (OUTFLOW[elm.code as keyof typeof OUTFLOW]) {
						if (!groupNamesWhichDontShow.includes(elm.code)) {
							addItemValueToHouseholdArray(elm, householdArray, false);
						}
					}

					if (INCOME[elm.code as keyof typeof INCOME]) {
						addItemValueToHouseholdArray(elm, householdArray, false);
					}

					if (ALIMONY[elm.code as keyof typeof ALIMONY]) {
						addItemValueToHouseholdArray(elm, householdArray, false);
					}

					if (WELFARE[elm.code as keyof typeof WELFARE]) {
						addItemValueToHouseholdArray(elm, householdArray, false);
					}

					if (ENTERTAINMENT_COMMUNICATION[elm.code as keyof typeof ENTERTAINMENT_COMMUNICATION]) {
						householdArray.push(elm);
					}

					if (elm.code === NAMECHECK) {
						dispatch(reportsActions.setNamecheck(elm));
					}

					if (elm.code === 'SCORE') {
						dispatch(reportsActions.setScore(elm));
					}

					if (elm.code === 'LIGHTS') {
						dispatch(reportsActions.setLights(elm));
					}
				}
				if (elm.hasOwnProperty('components')) {
					// This is for JSON tree which will change
					elm.components.forEach((comp: ICheckItem) => {
						if (comp.hasOwnProperty('components')) {
							comp.components.forEach((res: ICheckItem) => {
								addItemValueToHouseholdArray(res, householdArray, true, comp.code);
							});
						} else {
							addItemValueToHouseholdArray(comp, householdArray, false);
						}
					});
				}
			});
		}
		dispatch(reportsActions.setKontoCheck(kontoCheckArray));
		dispatch(reportsActions.setHousehold(householdArray));
	};
};

export const addItemValueToHouseholdArray = (
	elm: ICheckItem,
	householdArray: any,
	ifParent: boolean,
	parentCode?: string
) => {
	let parsedValue = parseResultForValue(elm, false);
	if (Object.keys(parsedValue).length === 0) {
		parsedValue = ifParent ? { ...elm, parent: parentCode } : { ...elm };
	}
	householdArray.push(parsedValue);
};

export const checkAndSavePersonaIfAllDataIsCollected = () => {
	return async (dispatch: any, getState: any) => {
		const { persona } = getState();
		const {
			firstName,
			lastName,
			dateOfBirth,
			residence: { country },
			consentCollectingData,
			consentForwardingData
		} = persona;
		if (firstName && lastName && dateOfBirth && country && consentCollectingData && consentForwardingData) {
			await dispatch(putPersonaData(persona));
		}
	};
};

export const handleNextThunk = ({ status }: { status?: string }) => {
	return async (dispatch: any, getState: any) => {
		const state = getState() as RootState;

		const activeStep = state.ui.activeStep;
		const showResults = selectShowResults(state);
		const selfAssessment = selectFlag(state, FlagsEnum.ASSESSMENT_USER_INPUT);
		const persona = selectPersona(state);

		const activeStepIndex = Number.parseInt(activeStep.id);
		const nextStep = stepsConstant[activeStepIndex + 1];

		switch (activeStep.key) {
			case EnumSteps.FinalStep:
			case EnumSteps.ErrorStep:
				return;
			case EnumStepsKeys.SECOND_STEP:
				try {
					await dispatch(checkAndSavePersonaIfAllDataIsCollected());
					return;
				} catch (error) {
					dispatch(handleError(error));
				}
				break;
			case EnumStepsKeys.WAITING_RESULTS:
				if (showResults) {
					// if flag SELF ASSESSMENT is SET go to RESULTS VIEW page (else go to RESULTS SUMMARY page)
					if (selfAssessment) dispatch(uiActions.setCheckResultsViewType(EnumResultsPageType.RESULTS_VIEW));
					else dispatch(uiActions.setCheckResultsViewType(EnumResultsPageType.RESULTS_SUMMARY));
					dispatch(uiActions.setActiveStep(EnumStepsKeys.CHECK_STEP));
				} else {
					// else go to final step
					dispatch(uiActions.setActiveStep(EnumStepsKeys.FINAL_STEP));
				}
				return;
			case EnumStepsKeys.CHECK_STEP:
				const _status = status ? status : 'accepted';
				await dispatch(completeCheck(_status as CompleteStatus, persona.userAssessment.user));
				return;
			default:
				break;
		}

		dispatch(uiActions.setActiveStep(nextStep.key));
	};
};
